import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Typography,
  Form,
  Button,
  Col,
  Select,
  Input,
  Radio,
  InputNumber,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import API from "../../../shared/api/api";
import { useParams } from "react-router-dom";
import {
  rule_float_number,
  rule_number,
  rule_require,
} from "../../../shared/validateInput";
import { IPlace } from "../interface";
import {
  DirectionsRenderer,
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { GOOGLE_MAP_KEY } from "../../../shared/key";
import { ListSearchLocation } from "../ListSearchLocation";
import _ from "lodash";
import {FREIGHT_ROAD_OPTIONS} from "../../../shared/define";
import moment from "moment/moment";

const { Title } = Typography;
const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};
const { Option } = Select;
function UpdateRouteWithPath() {
  const mapRef = useRef<any>(null);

  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [spinning, setSpinning] = useState<boolean>(true);
  let { id }: any = useParams();
  const [listPlaces, setListPlaces] = useState<IPlace[]>([]);
  const [listDirection, setListDirection] = useState<any[]>([]);
  const [list, setList] = useState<IPlace[]>([]);
  const [customers, setCustomers] = useState([]);
  const [freightVersions, setFreightVersions] = useState([]);

  console.log("listPlaces", list, listPlaces);

  useEffect(() => {
    getListCity();
    loadData();
  }, []);
  const loadData = () => {
    setSpinning(true);
    API.get(`admin/freight/${id}`)
      .then((res) => {
        if (res.data.success) {
          let listData = res.data.data;
          const listPath = listData?.path?.map((item: any, index: number) => {
            if (index === 0) {
              return { ...item, position: "start" };
            }
            if (index === listData?.path?.length - 1) {
              return { ...item, position: "end" };
            } else {
              return item;
            }
          });
          console.log("listPath", listData?.path);

          setListPlaces(listPath);
          form.setFieldsValue(listData);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSpinning(false);
      });
  };
  const getListCity = () => {
    axios
      .get("https://vapi.vnappmob.com/api/province")
      .then((res: any) => {
        if (res.status === 200) {
          setCityList(res.data.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onUpdate = async () => {
    setLoadingBtn(true);
    console.log("chat chua", form.getFieldValue("price"));
    // if (
    //   form.getFieldValue(["price", "container"]) ||
    //   form.getFieldValue(["price", "truck"])
    // ) {
    const customdata = { ...form.getFieldsValue() };
    if (!form.getFieldValue(["price", "container"]))
      customdata.price.container = 0;
    if (!form.getFieldValue(["price", "truck"])) customdata.price.truck = 0;
    const payload = { ...customdata, path: list };
    const response = await API.put(`admin/freight/${id}`, payload).then(
      (res) => res.data
    );
    if (response.success) history.push("/setting/price");
    // }
    setLoadingBtn(false);
  };
  useEffect(() => {
    if (listPlaces) {
      const totalDistance = _.sumBy(listPlaces, (i: IPlace) => {
        return i?.distance ?? 0;
      });
      console.log({ totalDistance });

      form.setFieldsValue({ distance: totalDistance });

      const realList = listPlaces.filter((item) => item.lat && item.lng);
      setList(realList);
    }
  }, [listPlaces]);

  const containerStyle = {
    width: "100%",
    height: "100vh",
  };

  const [center, setCenter] = useState({
    lat: 21.028511,
    lng: 105.804817,
  });

  const options = {
    strokeColor: "#3984fc",
    strokeOpacity: 0.8,
    strokeWeight: 5,
    fillColor: "#3984fc",
    fillOpacity: 0.35,
    clickable: false,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 99,
  };

  const setListDir = (dir: any, index: number) => {
    const temp = list.filter((item, index) => index > 0);
    temp[index] = dir;
    setListDirection(temp);
  };
  const calculateDir = (origin: IPlace, destination: IPlace, index: number) => {
    const directionService = new google.maps.DirectionsService();
    directionService.route(
      {
        destination: new google.maps.LatLng({
          lat: destination?.lat,
          lng: destination?.lng,
        }),
        origin: new google.maps.LatLng({
          lat: origin?.lat,
          lng: origin?.lng,
        }),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      async (response, status) => {
        setListDir(response, index);
      }
    );
  };
  useEffect(() => {
    if (list.length > 0) {
      list.map((item, index) => {
        if (index > 0) {
          calculateDir(list[index - 1], item, index - 1);
        }
      });
    }
  }, [list]);

  const handleChangeCustomer = (value: string) => {
    form.setFieldsValue({ customer: value });
  };

  const handleChangeFreightVersion = (value: string) => {
    form.setFieldsValue({ versionId: value });
  };

  useEffect(() => {
    fetchCustomer();
    fetchVersion();
  }, []);

  const fetchCustomer = async () => {
    const response: any = await API.get("admin/customer/all").then(
      (res) => res.data
    );

    if (response.success) {
      if (response.data) {
        const result = response?.data.map((item: any) => {
          return { label: `${item?.name} (${item?.code})`, value: item?._id };
        });
        setCustomers(result);
      }
    }
  };
  const fetchVersion = () => {
    API.get("admin/freight/version")
      .then((res) => {
        if (res.data.success) {
          const listData = res.data.data;
          listData.sort((a, b) => {
            if (a.state === 'VALID') return -1;
            if (b.state === 'VALID') return 1;
            return new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime()
          })
          const listVersion = listData.map((item: any) => {
            return { label: `${item?.name} (${moment(item?.validFrom).format('DD-MM-yyyy')})`, value: item?._id };
          })
          setFreightVersions(listVersion);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  return (
    <>
      <Row style={{ width: "100%" }}>
        <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
          <span
            onClick={() => {
              history.goBack();
            }}
          >
            <LeftOutlined />
            Quay lại
          </span>
        </Title>

        <Row gutter={[4, 0]} style={{ width: "120vw" }}>
          <Col lg={24} xs={24} sm={16}>
            <Row>
              <Col
                xs={24}
                // style={{ height: '100vh', border: '1px solid greyMa' }}
              >
                <Col xs={24}>
                  <LoadScript
                    googleMapsApiKey={GOOGLE_MAP_KEY}
                    libraries={["places"]}
                  >
                    <div style={{ display: "flex" }}>
                      <Col lg={6}>
                        <Col xs={24} sm={8}>
                          <Title level={5}>Lộ trình</Title>
                        </Col>
                        <ListSearchLocation
                          list={listPlaces}
                          setList={setListPlaces}
                        />
                        <Form
                          form={form}
                          {...layout}
                          //   style={{ width: '100%' }}
                          onFinish={onUpdate}
                          onFinishFailed={() => {
                            setLoadingBtn(false);
                          }}
                          style={{ marginTop: 10 }}
                        >
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item label="Phiên bản" name="versionId">
                              <Select
                                // defaultValue={freightVersions}
                                style={{ width: "100%" }}
                                onChange={handleChangeFreightVersion}
                                options={freightVersions}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item label="Mã tuyến" name="code">
                              <Input placeholder={"Nhập mã tuyến"} />
                            </Form.Item>
                          </Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item label="Mô tả" name="description">
                              <Input placeholder={"Nhập mô tả"} />
                            </Form.Item>
                          </Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item label="Khách hàng" name="customer">
                              <Select
                                // defaultValue="lucy"
                                style={{ width: "100%" }}
                                onChange={handleChangeCustomer}
                                options={customers}
                              />
                            </Form.Item>
                          </Col>
                          {/* <Row gutter={[8, 0]} style={{ width: '100%' }}> */}
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item
                              label="Km có hàng(km)"
                              name="haveGoodsDistance"
                            >
                              <InputNumber
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value) =>
                                  `${value}`?.replace(/\$\s?|(,*)/g, "")
                                }
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item
                              label="Km không hàng(km)"
                              name="noGoodsDistance"
                            >
                              <Input type="number" style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item
                              label="Tiền đi đường"
                              name="salary"
                              rules={[rule_float_number]}
                            >
                              <InputNumber
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value) =>
                                  `${value}`?.replace(/\$\s?|(,*)/g, "")
                                }
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item
                              label="Tiền đi đường (kết hợp)"
                              name="salaryCombined"
                              rules={[rule_float_number]}
                            >
                              <InputNumber
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value) =>
                                  `${value}`?.replace(/\$\s?|(,*)/g, "")
                                }
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          {/* <Col span={8}></Col> */}
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item
                              label="Phương thức"
                              name="roadType"
                              rules={[rule_require]}
                            >
                              <Radio.Group>
                                {
                                  FREIGHT_ROAD_OPTIONS.map(type => {
                                    return <Radio value={type.value}>{type.label}</Radio>
                                  })
                                }
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={16}></Col>
                          <Col xs={24} sm={8}>
                            <Title level={5}>Đường bộ</Title>
                          </Col>
                          <Col xs={0} sm={16}></Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item
                              label="Giá cước cont 20"
                              name={["price", "cont20"]}
                              rules={[rule_float_number]}
                            >
                              <Input placeholder={"Nhập giá cước"} />
                            </Form.Item>
                          </Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item
                              label="Giá cước cont 40"
                              name={["price", "cont40"]}
                              rules={[rule_float_number]}
                            >
                              <Input placeholder={"Nhập giá cước"} />
                            </Form.Item>
                          </Col>
                          <Col sm={8}></Col>

                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item
                              label="Giá CY cont 20"
                              name={["price", "cy20"]}
                              rules={[rule_float_number]}
                            >
                              <InputNumber
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value) =>
                                  `${value}`?.replace(/\$\s?|(,*)/g, "")
                                }
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item
                              label="Giá CY cont 40"
                              name={["price", "cy40"]}
                              rules={[rule_float_number]}
                            >
                              <InputNumber
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value) =>
                                  `${value}`?.replace(/\$\s?|(,*)/g, "")
                                }
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm={8}></Col>

                          <Col lg={22} xs={24} sm={6}>
                            <Form.Item
                              label="Giá cước xe tải"
                              name={["price", "truck"]}
                              rules={[rule_float_number]}
                            >
                              <Input placeholder={"Nhập giá cước"} />
                            </Form.Item>
                          </Col>
                          {/* </Row> */}
                          <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={8}>
                            <Button
                              loading={isLoadingBtn}
                              htmlType="submit"
                              style={{
                                width: "100%",
                                backgroundColor: "#03A9F4",
                                color: "white",
                              }}
                            >
                              Cập nhật
                            </Button>
                          </Col>
                        </Form>
                      </Col>

                      <div style={containerStyle}>
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          // zoom={8}
                          center={center}
                          ref={mapRef}
                        >
                          {/* {listPlaces && (
                            <>
                              {listPlaces.map((item) => {
                                if (item.polyline) {
                                  return (
                                    <Polyline
                                      path={decode(item?.polyline ?? '').map(
                                        (item) => {
                                          return {
                                            lat: item.latitude,
                                            lng: item.longitude,
                                          };
                                        }
                                      )}
                                      options={options}
                                    />
                                  );
                                }
                              })}
                            </>
                          )} */}

                          {listDirection.length > 0 && (
                            <>
                              {listDirection.map((item) => {
                                return (
                                  <DirectionsRenderer
                                    options={{
                                      directions: item,
                                      markerOptions: {
                                        icon: "/icon/receipt.png",
                                      },
                                      suppressMarkers: true,
                                    }}
                                  />
                                );
                              })}
                            </>
                          )}
                          {listPlaces.length > 1 && (
                            <>
                              {listPlaces.map((item, index) => {
                                if (index > 0) {
                                  if (index === listPlaces.length - 1) {
                                    return (
                                      <Marker
                                        position={{
                                          lat: listPlaces[listPlaces.length - 1]
                                            ?.lat,
                                          lng: listPlaces[listPlaces.length - 1]
                                            ?.lng,
                                        }}
                                        icon="/icon/delivery.png"
                                      />
                                    );
                                  }
                                  return (
                                    <Marker
                                      position={{
                                        lat: item?.lat,
                                        lng: item?.lng,
                                      }}
                                      icon="/icon/marker.png"
                                    />
                                  );
                                }
                              })}
                            </>
                          )}

                          {/* {listPlaces.length > 1 && (
                            <Marker
                              position={{
                                lat: listPlaces[listPlaces.length - 1]?.lat,
                                lng: listPlaces[listPlaces.length - 1]?.lng,
                              }}
                              icon="/icon/delivery.png"
                            />
                          )} */}
                          {listPlaces.length > 0 && (
                            <Marker
                              position={{
                                lat: listPlaces[0]?.lat,
                                lng: listPlaces[0]?.lng,
                              }}
                              icon="/icon/receipt.png"
                            />
                          )}
                        </GoogleMap>
                      </div>
                    </div>
                  </LoadScript>
                </Col>
                {/* } */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </>
  );
}

export default UpdateRouteWithPath;
