import React, {useEffect, useState} from 'react';
import {
  Space,
  Button,
  Table,
  Typography,
  Input,
  Avatar,
  Col,
  Select,
  Row,
  Tooltip,
  Popconfirm,
  DatePicker,
  Form,
  Modal,
  Upload
} from 'antd'
import {Link} from 'react-router-dom';
import {PlusOutlined, FileExcelOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
import API from '../../../shared/api/api'
import {REPAIR_TYPE} from '../../../shared/define';
import moment from 'moment';
import {getRepairList, deleteRepair} from '../../../shared/api/Repair';
import * as FileSaver from "file-saver";
import layout from "antd/es/layout";

const {RangePicker} = DatePicker;
const {TextArea} = Input;


const {Title} = Typography;
const {Search} = Input
const {Option} = Select;

function RepairList() {
  const defaultImportingFile = {
    imported: false,
    fileToImport: '',
    countAdded: 0,
    addedErrorList: []
  }
  const [checkingFile, setCheckingFile] = useState(false)
  const [executingImportFile, setExecutingImportFile] = useState(false)
  const [dataChecked, setDataChecked] = useState(defaultImportingFile)
  const [dataAfterImported, setDataAfterImported] = useState(defaultImportingFile)
  const [importModal, setImportModal] = useState(false)
  const [form] = Form.useForm();

  const [repairList, setRepairList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [summaryCost, setSummaryCost] = useState(0);
  const [isloading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({})
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0
  })
  useEffect(() => {
    setIsLoading(true);
    loadList();
  }, [filter, pagination.current]);

  useEffect(() => {
    getOwnerVehicleList()
  }, [])

  const loadList = async () => {
    setIsLoading(true)
    const response: any = await getRepairList({...filter, current: pagination.current, pageSize: pagination.pageSize})
    if (response) {
      setRepairList(response.data)
      setPagination({...pagination, total: response.total})
      setIsLoading(false)
      setSummaryCost(response.summaryAmount);
    }
  }

  const getOwnerVehicleList = () => {
    API.get('admin/vehicle/', {params: {ownership: 'INVESTMENT'}})
      .then(
        res => {
          if (res.data.success) {
            setVehicleList(res.data.data)
          }
        }
      )
      .catch(error => {
        console.log(error);
      })
  }
  const preCheckImportFile = (options: any) => {
    const {onSuccess, onError, file} = options;
    const formData = new FormData();
    setCheckingFile(true);
    formData.append("file", file);
    API.post("admin/repair/import/pre-check", formData)
      .then((res) => {
        if (res.data.success) {
          onSuccess("Ok");
          console.log('response', res.data)
          setDataChecked(res.data?.data || defaultImportingFile)
        }
        setCheckingFile(false);
      })
      .catch((err) => {
        onError({err});
        console.log("Error: ", err);
        setCheckingFile(false);
      });
  };

  const onConfirmImport = async () => {
    if (!dataChecked.fileToImport) {
      alert('Chưa có file import')
      return;
    }
    const body = {
      fileName: dataChecked.fileToImport,
    }
    setExecutingImportFile(true)
    API.post("admin/repair/import/confirm", body)
      .then((res) => {
        if (res.data.success) {
          setExecutingImportFile(false)
          const result = res.data?.data
          if (result.addedErrorList?.length === 0) {
            setDataAfterImported(defaultImportingFile)
            handleCloseModal();
            loadList();
            return;
          }
          setDataAfterImported({...result, imported: true})
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
        setExecutingImportFile(false)
      });
  };

  const exportData = (options: any) => {
    const {onSuccess, onError} = options;
    API.get("admin/repair/export", {responseType: "blob"})
      .then((res) => {
        console.log(res.data)
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(blob, 'repair_export_' + moment().format("yyyyMMDD") + '.xlsx');
      })
      .catch((err) => {
        onError({err});
        console.log("Error: ", err);
      });
  };
  const handleCloseModal = () => {
    setImportModal(false);
    setDataChecked(defaultImportingFile)
    // setSelectedItem(undefined);
    form.resetFields();
  };
  const handleClick = async (id: any) => {
    const res = await deleteRepair(id)
    if (res.success) loadList()
  }
  const columns: Array<object> = [
    {
      title: 'STT',
      dataIndex: '_id',
      render: (id: any, record: any, index: any) => index + 1
    },
    {
      title: 'Tên sửa chữa',
      dataIndex: 'name',
    },
    {
      title: 'Loại sửa chữa',
      dataIndex: 'type',
      render: (type: any) => REPAIR_TYPE.find((item: any) => item.value === type)?.label
    },
    {
      title: 'Giá',
      dataIndex: 'amount',
      render: (price: any, record: any) => (
        <>{new Intl.NumberFormat().format(price?.toString())}đ</>
      ),
    },
    {
      title: 'Ngày sửa chữa',
      dataIndex: 'date',
      render: (dateOfBirth: any) => moment(dateOfBirth).format('DD/MM/YYYY')
    },
    {
      title: 'Xe',
      dataIndex: ['vehicle', 'licensePlate'],
    },
    {
      title: 'Tác vụ',
      align: "center",
      width: 100,
      render: (item: any) => (
        <Space size="middle">
          <Tooltip title="Sửa">
            <Link to={{
              pathname: `/resource/repair/${item._id}/edit`,
            }}>
              <EditOutlined style={{fontSize: 16}}/>
            </Link>
          </Tooltip>
          <Popconfirm
            title="Bạn có muốn xóa?"
            onConfirm={() => handleClick(item._id)}
            okText="Có"
            cancelText="Hủy"
          >
            <DeleteOutlined style={{fontSize: 16, color: "#FF4E4F", cursor: "pointer"}}/>
          </Popconfirm>
        </Space>
      ),
    }
  ];

  return (
    <>
      <Title level={4}>Quản lý chi phí sửa chữa ({pagination.total})</Title>
      <Title level={5}
             style={{color: 'orange'}}>Tổng: {summaryCost.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"}</Title>
      <Row justify="space-between" gutter={8}>
        <Col>
          <Select
            dropdownMatchSelectWidth={false}
            showSearch
            size="small"
            style={{width: 220}}
            placeholder="Lọc theo xe"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSelect={(value) => setFilter({...filter, vehicle: value})}
          >
            <Option value="ALL">Tất cả</Option>
            {vehicleList.map((vehicle: any) =>
              <Option key={vehicle._id} value={vehicle._id}>{vehicle.licensePlate}</Option>
            )}
          </Select>
          <RangePicker
            size="small"
            style={{width: 180}}
            onChange={(date, dateString) => {
              setFilter({
                ...filter,
                range: date ? [
                    date?.[0]?.format("YYYY-MM-DD HH:mm:ssZ"),
                    date?.[1]?.format("YYYY-MM-DD HH:mm:ssZ"),
                  ]
                  :
                  [],
              })
            }
            }
          />
          <Space size={8}>
            Chỉ định
            <Select size="small" defaultValue="ALL" style={{width: 150}}
                    onChange={(value) => setFilter({...filter, driverStatus: value})}>
              <Option value="ALL">Tất cả</Option>
              <Option value="ASSIGNED">Đã chỉ định</Option>
              <Option value="AVAILABLE">Chưa chỉ định</Option>
            </Select>
            <br/><br/><br/>
            Loại xe
            <Select size="small" defaultValue="ALL" style={{width: 150}}
                    onChange={(value) => setFilter({...filter, type: value})}>
              <Option value="ALL">Tất cả</Option>
              <Option value="CONTAINER">Xe Container</Option>
              <Option value="TRUCK">Xe tải</Option>
            </Select>
          </Space>
        </Col>
        <Space style={{display: 'flex', justifyContent: 'flex-end'}}>
          {/*<Search*/}
          {/*  disabled*/}
          {/*  placeholder="Search"*/}
          {/*  allowClear*/}
          {/*  size="small"*/}
          {/*  style={{width: 150, margin: '0 10px'}}*/}
          {/*  onSearch={(value) => setFilter({...filter, text: value})}*/}
          {/*/>*/}
          <Button
            disabled={false}
            size="small"
            style={{backgroundColor: "#009688", borderColor: "#009688"}}
            type="primary"
            icon={<FileExcelOutlined/>}
            onClick={() => setImportModal(true)}
          >
            Import dữ liệu
          </Button>
          <Button
            size="small"
            style={{backgroundColor: "#002696", borderColor: "#001131"}}
            type="primary"
            icon={<FileExcelOutlined/>}
            onClick={exportData}
          >
            Xuất dữ liệu
          </Button>
          <Link to={{
            pathname: '/resource/repair/create',
          }} style={{color: 'white'}}>
            <Button size="small" style={{color: 'white', border: 'none'}} type="primary" icon={<PlusOutlined/>}>
              Tạo mới
            </Button>
          </Link>
        </Space>
      </Row>
      <br/>
      <Table
        columns={columns}
        rowKey={(repair: any) => repair._id}
        pagination={pagination}
        onChange={(value: any) => setPagination({...pagination, current: value.current})}
        dataSource={repairList}
        loading={isloading}
      />
      <Modal
        open={importModal}
        style={{width: 600}}
        title={"Import dữ liệu sửa chữa"}
        onCancel={handleCloseModal}
        footer={null}
        closable={true}
        // onOk={() => {
        //   form.submit();
        //   setImportModal(false);
        // }}
      >
        <Form {...layout} form={form} onFinish={onConfirmImport} layout="horizontal">
          <Form.Item name="_id" label="id" hidden>
            <Input type="text" disabled hidden/>
          </Form.Item>
          <Row style={{padding: 10}}>
            <Col style={{marginRight: 10}}>
              <Upload showUploadList={false} customRequest={preCheckImportFile}>
                <Button
                  disabled={checkingFile}
                  size="small"
                  style={{backgroundColor: "#005896", borderColor: "#005896", color: 'whitesmoke'}}
                  type="primary"
                  loading={checkingFile}
                  icon={<FileExcelOutlined/>}
                >
                  Chọn file import
                </Button>
              </Upload>
            </Col>
            {
              dataChecked.fileToImport &&
              <Col>
                <Form.Item label="File:">
                  {dataChecked.fileToImport}
                </Form.Item>
              </Col>
            }
          </Row>
          <Row>
            <Col>
              {
                dataChecked.fileToImport &&
                <Row style={{marginTop: -30}}>
                  <Form.Item label="Thêm mới:" style={{flexDirection: 'row'}}>
                    {dataChecked.countAdded} bản ghi
                  </Form.Item>
                  {
                    dataChecked.addedErrorList.length > 0 &&
                    <Form.Item label="Lỗi:" style={{flexDirection: 'row', marginLeft: 50}}>
                      {dataChecked.addedErrorList.length} bản ghi
                    </Form.Item>
                  }
                </Row>
              }
              {/*<Row style={{marginTop: -30}}>*/}
              {/*  <Form.Item label="Cập nhật:" style={{flexDirection: 'row'}}>*/}
              {/*    {fileToImport.countUpdate} bản ghi*/}
              {/*  </Form.Item>*/}
              {/*  {*/}
              {/*    fileToImport.updatedErrorList.length > 0 &&*/}
              {/*    <Form.Item label="Lỗi:" style={{flexDirection: 'row', marginLeft: 50}}>*/}
              {/*      {fileToImport.updatedErrorList.length} bản ghi*/}
              {/*    </Form.Item>*/}
              {/*  }*/}
              {/*</Row>*/}
            </Col>
            <Col>
              <TextArea
                disabled={true}
                style={{height: 220, width: 500, resize: 'none', overflow: 'scroll', marginBottom: 10}}
                // value={fileToImport.addedErrorList.reduce((a, b) => a.code + ', ' + a.truckVendorName + ', ' + item.reason).join('\n')}
                value={dataAfterImported.imported ?
                  (dataAfterImported.addedErrorList.length > 0 ?
                    ('Lỗi thêm mới:' + dataAfterImported.addedErrorList.map(item => {
                      return '\n' + (item.index + 1) + ' ' + item.vehicle + ', ' + item.type + ', ' + item.reason
                    })) : '')
                  // + (fileImported.updatedErrorList.length > 0 ?
                  //     ('\nLỗi cập nhật:' + fileImported.updatedErrorList.map(item => {
                  //       return '\n' + (item.index + 1) + ' ' + item.code + ', ' + item.customer + ', ' + item.reason + ', ' + item.address
                  //     })) : ''
                  // )
                  :
                  (dataChecked.addedErrorList.length > 0 ?
                    ('Kiểm tra lỗi thêm mới:' + dataChecked.addedErrorList.map(item => {
                      return '\n' + (item.index + 1) + ' ' + item.vehicle + ', ' + item.type + ', ' + item.reason
                    })) : '')
                  // + (fileToImport.updatedErrorList.length > 0 ?
                  //     ('\nKiểm tra lỗi cập nhật:' + fileToImport.updatedErrorList.map(item => {
                  //       return '\n' + (item.index + 1) + ' ' + item.code + ', ' + item.customer + ', ' + item.reason + ', ' + item.address
                  //     })) : ''
                  // )
                }
              />
            </Col>
          </Row>
          <Col>
            {
              dataAfterImported.imported && <>
                Kết quả Import:
                <Form.Item label={'Thêm mới:'}>
                  {dataAfterImported.countAdded} thành công, {dataAfterImported.addedErrorList.length} thất bại
                </Form.Item>
              </>
            }
          </Col>
          {/*<Col style={{marginTop: -30}}>*/}
          {/*  {*/}
          {/*    fileImported.imported && <Form.Item label={'Cập nhật:'}>*/}
          {/*      {fileImported.countUpdate} thành công, {fileImported.updatedErrorList.length} thất bại*/}
          {/*    </Form.Item>}*/}
          {/*</Col>*/}
          <Form.Item>
            <Button
              key="submit"
              disabled={checkingFile ||
                executingImportFile ||
                dataChecked.fileToImport.length === 0 ||
                dataChecked.addedErrorList.length !== 0
                // fileToImport.updatedErrorList.length !== 0
              }
              style={{backgroundColor: "#233dce", borderColor: "#233dce", color: 'white'}}
              type="primary"
              block
              loading={executingImportFile}
              onClick={() => {
                form.submit();
              }}
            >Xác nhận import</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default RepairList