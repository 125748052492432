import React, {useEffect, useState} from "react";
import API from "../../../shared/api/api";
import {
  Button,
  Col, Form,
  Input, InputNumber, Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  Upload,
  DatePicker, Popconfirm
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EnvironmentOutlined,
  FileExcelOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import axios from "axios";
import * as FileSaver from "file-saver";
import moment from "moment/moment";
import layout from "antd/es/layout";
import dayjs from "dayjs";

const {Search} = Input;
const {Title} = Typography;
const {Option} = Select;

function PriceContractorList() {
  const defaultImportingFile = {
    imported: false,
    fileToImport: '',
    countAdded: 0,
    // countUpdate: 0,
    // updatedErrorList: [],
    addedErrorList: [],
    versionName: '',
    versionId: '',
  };
  const {TextArea} = Input;
  const [priceList, setPriceList] = useState([]);
  const [versionList, setVersionList] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState<any>("");
  const [isLoadingVersion, setIsLoadingVersion] = useState(true);
  const [isLoadingPrice, setIsLoadingPrice] = useState(true);
  const [filter, setFilter] = useState({});
  const [truckVendorList, setTruckVendorList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [drawerVisible, setDrawerVisible] = useState<Boolean>(false);
  const [id, setId] = useState("");
  const [route, setRoute] = useState("");

  const [checkingFile, setCheckingFile] = useState(false)
  const [executingImportFile, setExecutingImportFile] = useState(false)
  const [dataChecked, setDataChecked] = useState(defaultImportingFile)
  const [dataAfterImported, setDataAfterImported] = useState(defaultImportingFile)
  const [importingValidDate, setImportingValidDate] = useState(dayjs())
  const [importModal, setImportModal] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    loadVersion();
  }, []);

  useEffect(() => {
    selectedVersion && loadlist(selectedVersion);
  }, [filter, pagination.current, pagination.pageSize, selectedVersion]);

  useEffect(() => {
    // getListProvince();
    getProvinces();
    getTruckVendor();
  }, []);

  const loadVersion = () => {
    setIsLoadingVersion(true)
    API.get("admin/contractorFreight/version")
      .then((res) => {
        if (res.data.success) {
          const listData = res.data.data;
          listData.sort((a, b) => {
            if (a.state === 'VALID') return -1;
            if (b.state === 'VALID') return 1;
            return new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime()
          })
          listData.forEach((item: { key: any; _id: any }) => {
            item.key = item._id;
          });
          console.log('listData', listData)
          setVersionList(listData);
          listData && setSelectedVersion(listData[0])
          loadlist(listData[0]._id);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingVersion(false);
      });
  }
  const loadlist = (selectedVersion: any) => {
    if (!selectedVersion?._id) {
      return;
    }
    setIsLoadingPrice(true);
    API.get("admin/contractorFreight", {
      params: {
        ...filter,
        current: pagination.current,
        pageSize: pagination.pageSize,
        version: selectedVersion._id
      },
    })
      .then((res) => {
        if (res.data.success) {
          const listData = res.data.data;
          listData.forEach((item: { key: any; _id: any }) => {
            item.key = item._id;
          });
          setPriceList(listData);
          setPagination({...pagination, total: res.data.totalItem});
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingPrice(false);
      });
  };

  const getProvinces = () => {
    API.get("admin/region/province", {
      params: {},
    })
      .then((res) => {
        if (res.data.success) {
          setProvinceList(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const getTruckVendor = () => {
    API.get("admin/truck-vendor/all", {
      params: {},
    })
      .then((res) => {
        if (res.data.success) {
          setTruckVendorList(res.data.data.sort((a: any, b: any) => a.shortName.localeCompare(b.shortName)));
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const columns: Array<object> = [
    {
      title: "STT",
      dataIndex: "_id",
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Nhà thầu",
      dataIndex: "truckVendor",
      render: (truckVendor: any) => (
        <Tooltip
          placement="topLeft"
          title={`${truckVendor.shortName}`}
        >
          <div className="address">
            {truckVendor.shortName}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Mã nhận",
      dataIndex: ["receipt", "code"],
    },
    {
      title: "Điểm nhận",
      dataIndex: "receipt",
      render: (receipt: any) => (
        <Tooltip
          placement="topLeft"
          title={`${receipt.name} -- ${receipt.pName}`}
        >
          <div className="address">
            {receipt.name} -- {receipt.pName}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Mã trả",
      dataIndex: ["delivery", "code"],
    },
    {
      title: "Điểm trả",
      dataIndex: "delivery",
      render: (delivery: any) => (
        <Tooltip
          placement="topLeft"
          title={`${delivery.name} -- ${delivery.pName}`}
        >
          <div className="address">
            {delivery.name} -- {delivery.pName}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Mô tả",
      dataIndex: "description",
    },
    {
      title: "Giá container 20",
      dataIndex: ["price", "cont20"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Giá container 40",
      dataIndex: ["price", "cont40"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Giá CY 20",
      dataIndex: ["price", "cy20"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Giá CY 40",
      dataIndex: ["price", "cy40"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Tác vụ",
      render: (price: any) => (
        moment(selectedVersion.validFrom) > moment() ?
          <>
            <Tooltip title="Sửa">
              <Link
                to={{
                  pathname: `/route-contractor-with-path/edit/${price._id}`,
                  // pathname: `/basic-route/edit/${price._id}`,
                }}
              >
                <EditOutlined style={{fontSize: 16}}/>
              </Link>
            </Tooltip>
            <Popconfirm
              placement="topLeft"
              title={"Bạn có chắc xoá?"}
              onConfirm={async () => {
                const response = await API.delete(
                  `admin/contractorFreight/${price._id}`
                ).then((res) => res.data);
                if (response.success) {
                  loadlist(selectedVersion)
                }
              }}
              okText="Có"
              cancelText="Không"
            >
              <Tooltip title="Xóa">
                <DeleteOutlined style={{fontSize: 16, color: "red", marginLeft: 10}}/>
              </Tooltip>
            </Popconfirm>
          </>
          :
          <></>
      ),
    },
  ];
  const versionColumns: Array<object> = [
    {
      title: "STT",
      dataIndex: "_id",
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Mã phiên bản",
      dataIndex: "code",
    },
    {
      title: "Tên phiên bản",
      dataIndex: "name",
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "validFrom",
      render: (validFrom: any) => validFrom ? moment(validFrom).format("DD/MM/YYYY") : 'N/A',
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "expiredDate",
      render: (expiredDate: any) => expiredDate ? moment(expiredDate).format("DD/MM/YYYY") : 'N/A',
    },
    {
      title: "Trạng thái",
      dataIndex: "state",
    },
    {
      title: "Tác vụ",
      render: (id: any, record: any) => (
        <>
          {
            record.filePath &&
            <Button type="text">
              <img
                style={{
                  height: "16px",
                  width: "16px",
                  // marginRight: "5px",
                  cursor: "pointer",
                }}
                src="/icon/ic-download.png"
                onClick={() => downloadVersion(record)}
              />
            </Button>
          }
          {moment(record.validFrom) > moment() ?
            <Popconfirm
              placement="topLeft"
              title={"Bạn có chắc xoá?"}
              onConfirm={async () => {
                const response = await API.delete(
                  `admin/contractorFreight/version/${record._id}`
                ).then((res) => res.data);
                if (response.success) {
                  loadVersion()
                }
              }}
              okText="Có"
              cancelText="Không"
            >
              <Tooltip title="Xóa">
                <DeleteOutlined style={{fontSize: 16, color: "red", marginLeft: 10}}/>
              </Tooltip>
            </Popconfirm>
            :
            <></>}
        </>
      ),
    },
  ];
  const preCheckImportFile = (options: any) => {
    const {onSuccess, onError, file} = options;
    const formData = new FormData();
    setCheckingFile(true);
    formData.append("file", file);
    API.post("admin/contractorFreight/import/pre-check", formData)
      .then((res) => {
        if (res.data.success) {
          onSuccess("Ok");
          console.log('response', res.data)
          setDataChecked(res.data?.data || defaultImportingFile)
        }
        setCheckingFile(false);
      })
      .catch((err) => {
        onError({err});
        console.log("Error: ", err);
        setCheckingFile(false);
      });
  };

  const onConfirmImport = async () => {
    if (!dataChecked.fileToImport) {
      alert('Chưa có file import')
      return;
    }
    const body = {
      fileName: dataChecked.fileToImport,
      versionId: dataChecked.versionId,
      validFrom: importingValidDate.format()
    }
    setExecutingImportFile(true)
    API.post("admin/contractorFreight/import/confirm", body)
      .then((res) => {
        if (res.data.success) {
          setExecutingImportFile(false)
          const result = res.data?.data
          if (result.addedErrorList?.length === 0) {
            setDataAfterImported(defaultImportingFile)
            handleCloseModal();
            loadVersion();
            return;
          }
          setDataAfterImported({...result, imported: true})
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
        setExecutingImportFile(false)
      });
  };

  const exportData = (options: any) => {
    const {onSuccess, onError} = options;
    API.get("admin/contractorFreight/export", {
      params: {
        versionId: selectedVersion._id
      },
      responseType: "blob"
    })
      .then((res) => {
        console.log(res.data)
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileSurFix = selectedVersion?.state === 'VALID'
          ? moment(selectedVersion?.validFrom).format("yyyyMMDD")
          : moment(selectedVersion?.validFrom).format("yyyyMMDD") + '-' + moment(selectedVersion?.expiredDate).format("yyyyMMDD")
        FileSaver.saveAs(blob, 'contractor_freight_export_' + fileSurFix + '.xlsx');
      })
      .catch((err) => {
        onError({err});
        console.log("Error: ", err);
      });
  };
  const downloadVersion = (version: any) => {
    API.get("admin/contractorFreight/version/" + version._id + "/download", {
      responseType: "blob"
    })
      .then((res) => {
        console.log(res.data)
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(blob, 'contractor_freight_' + version.fileName);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };
  const handleCloseModal = () => {
    setImportModal(false);
    setDataChecked(defaultImportingFile)
    // setSelectedItem(undefined);
    form.resetFields();
  };
  const rowClassName = (record: any) => {
    return record._id === selectedVersion._id
      ? "selected-row"
      : "";
  };
  return (
    <>
      <Title level={4}>Quản lý cước chi phí ({pagination.total})</Title>
      <Row justify="space-between" gutter={8}>
        <Col>
          <Space size={8}>
            Nhà xe
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              size="small"
              style={{width: 150}}
              placeholder="Search to Select"
              defaultValue="ALL"
              optionFilterProp="children"
              onSelect={(value: string) =>
                setFilter({...filter, truckVendor: value})
              }
            >
              <Option key="0" value="ALL">
                Tất cả
              </Option>
              {truckVendorList.map((truckVendor: any) => (
                <Option key={truckVendor._id} value={truckVendor._id}>
                  {truckVendor.shortName} {/*+ "(" + truckVendor.code + ")"}*/}
                </Option>
              ))}
            </Select>
            Điểm nhận
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              size="small"
              style={{width: 150}}
              placeholder="Search to Select"
              defaultValue="ALL"
              optionFilterProp="children"
              onSelect={(value: string) =>
                setFilter({...filter, receipt: value})
              }
            >
              <Option key="0" value="ALL">
                Tất cả
              </Option>
              {provinceList.map((province: any) => (
                <Option key={province._id} value={province._id}>
                  {province.name}
                </Option>
              ))}
            </Select>
            Điểm trả
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              size="small"
              style={{width: 150}}
              placeholder="Search to Select"
              defaultValue="ALL"
              optionFilterProp="children"
              onSelect={(value: string) =>
                setFilter({...filter, delivery: value})
              }
            >
              <Option key="0" value="ALL">
                Tất cả
              </Option>
              {provinceList.map((province: any) => (
                <Option key={province._id} value={province._id}>
                  {province.name}
                </Option>
              ))}
            </Select>
            Hiển thị
            <Select
              style={{width: 80}}
              size="small"
              placeholder="Search to Select"
              defaultValue={pagination.pageSize}
              onSelect={(value: number) =>
                setPagination({...pagination, pageSize: value})
              }
            >
              {[10, 20, 30, 40, 50].map((pageSize: any) => (
                <Option key={pageSize} value={pageSize}>
                  {pageSize}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Space>
          <Button
            disabled={false}
            size="small"
            style={{backgroundColor: "#009688", borderColor: "#009688"}}
            type="primary"
            icon={<FileExcelOutlined/>}
            onClick={() => setImportModal(true)}
          >
            Import bảng giá
          </Button>
          <Button
            size="small"
            style={{backgroundColor: "#002696", borderColor: "#001131"}}
            type="primary"
            icon={<FileExcelOutlined/>}
            onClick={exportData}
          >
            Xuất bảng giá
          </Button>
          {/*<Link*/}
          {/*  to={{*/}
          {/*    // pathname: '/route-with-path/create',*/}
          {/*    pathname: "/basic-route/create",*/}
          {/*  }}*/}
          {/*  style={{ color: "white" }}*/}
          {/*>*/}
          {/*  <Button*/}
          {/*    size="small"*/}
          {/*    style={{ backgroundColor: "#2E3D80", borderColor: "#2E3D80" }}*/}
          {/*    type="primary"*/}
          {/*    icon={<EnvironmentOutlined />}*/}
          {/*  >*/}
          {/*    Tạo chặng (cũ)*/}
          {/*  </Button>*/}
          {/*</Link>*/}
          <Link
            to={{
              pathname: "/route-contractor-with-path/create",
              // pathname: '/basic-route/create',
            }}
            style={{color: "white"}}
          >
            <Button
              size="small"
              style={{backgroundColor: "#2E3D80", borderColor: "#2E3D80"}}
              type="primary"
              icon={<EnvironmentOutlined/>}
            >
              Tạo chặng
            </Button>
          </Link>
          <Link
            to={{
              pathname: "/attribute-route/create",
            }}
            style={{color: "white"}}
          >
            <Button size="small" type="primary" icon={<PlusOutlined/>}>
              Thuộc tính
            </Button>
          </Link>
        </Space>
      </Row>
      <br/>
      <Table
        scroll={{x: "max-content"}}
        loading={isLoadingVersion}
        bordered={false}
        columns={versionColumns}
        dataSource={versionList}
        // rowSelection={{type: 'radio'}}
        onRow={(record, rowIndex) => ({
          onClick: () => {
            console.log("selected", record);
            setSelectedVersion(record);
          }
        })}
        rowClassName={rowClassName}
        // pagination={pagination.total > pagination.pageSize && pagination}
        // onChange={(value: any) =>
        //   setPagination({ ...pagination, current: value.current })
        // }
      />
      <Table
        scroll={{x: "max-content"}}
        loading={isLoadingPrice}
        bordered={false}
        columns={columns}
        dataSource={priceList}
        pagination={pagination.total > pagination.pageSize && pagination}
        onChange={(value: any) =>
          setPagination({...pagination, current: value.current})
        }
      />
      <Modal
        open={importModal}
        style={{width: 600}}
        title={"Import dữ liệu cước thầu phụ"}
        onCancel={handleCloseModal}
        footer={null}
        closable={true}
        // onOk={() => {
        //   form.submit();
        //   setImportModal(false);
        // }}
      >
        <Form {...layout} form={form} onFinish={onConfirmImport} layout="horizontal">
          <Form.Item name="_id" label="id" hidden>
            <Input type="text" disabled hidden/>
          </Form.Item>
          <Row style={{padding: 10}}>
            <Col style={{marginRight: 10}}>
              <Upload showUploadList={false} customRequest={preCheckImportFile}>
                <Button
                  disabled={checkingFile}
                  size="small"
                  style={{backgroundColor: "#009688", borderColor: "#009688"}}
                  type="primary"
                  loading={checkingFile}
                  icon={<FileExcelOutlined/>}
                >
                  Chọn file import
                </Button>
              </Upload>
            </Col>
            {
              dataChecked.fileToImport &&
              <Col>
                <Form.Item label="File:">
                  {dataChecked.fileToImport}
                </Form.Item>
              </Col>
            }
          </Row>
          <Row>
            <Col>
              {
                dataChecked.fileToImport &&
                <Row style={{marginTop: -30}}>
                  <Form.Item label="Thêm mới:" style={{flexDirection: 'row'}}>
                    {dataChecked.countAdded} bản ghi
                  </Form.Item>
                  {
                    dataChecked.addedErrorList.length > 0 &&
                    <Form.Item label="Lỗi:" style={{flexDirection: 'row', marginLeft: 50}}>
                      {dataChecked.addedErrorList.length} bản ghi
                    </Form.Item>
                  }
                </Row>
              }
              {/*<Row style={{marginTop: -30}}>*/}
              {/*  <Form.Item label="Cập nhật:" style={{flexDirection: 'row'}}>*/}
              {/*    {fileToImport.countUpdate} bản ghi*/}
              {/*  </Form.Item>*/}
              {/*  {*/}
              {/*    fileToImport.updatedErrorList.length > 0 &&*/}
              {/*    <Form.Item label="Lỗi:" style={{flexDirection: 'row', marginLeft: 50}}>*/}
              {/*      {fileToImport.updatedErrorList.length} bản ghi*/}
              {/*    </Form.Item>*/}
              {/*  }*/}
              {/*</Row>*/}
            </Col>
            <Col>
              <TextArea
                disabled={true}
                style={{height: 220, width: 500, resize: 'none', overflow: 'scroll', marginBottom: 10}}
                // value={fileToImport.addedErrorList.reduce((a, b) => a.code + ', ' + a.truckVendorName + ', ' + item.reason).join('\n')}
                value={dataAfterImported.imported ?
                  (dataAfterImported.addedErrorList.length > 0 ?
                    ('Lỗi thêm mới:' + dataAfterImported.addedErrorList.map(item => {
                      return '\n' + (item.index + 1) + ' ' + item.code + ', ' + item.truckVendorName + ', ' + item.reason + ', ' + item.address
                    })) : '')
                  // + (fileImported.updatedErrorList.length > 0 ?
                  //     ('\nLỗi cập nhật:' + fileImported.updatedErrorList.map(item => {
                  //       return '\n' + (item.index + 1) + ' ' + item.code + ', ' + item.customer + ', ' + item.reason + ', ' + item.address
                  //     })) : ''
                  // )
                  :
                  (dataChecked.addedErrorList.length > 0 ?
                    ('Kiểm tra lỗi thêm mới:' + dataChecked.addedErrorList.map(item => {
                      return '\n' + (item.index + 1) + ' ' + item.code + ', ' + item.truckVendorName + ', ' + item.reason + ', ' + item.address
                    })) : '')
                  // + (fileToImport.updatedErrorList.length > 0 ?
                  //     ('\nKiểm tra lỗi cập nhật:' + fileToImport.updatedErrorList.map(item => {
                  //       return '\n' + (item.index + 1) + ' ' + item.code + ', ' + item.customer + ', ' + item.reason + ', ' + item.address
                  //     })) : ''
                  // )
                }
              />
            </Col>
          </Row>
          <Col>
            {
              dataAfterImported.imported && <>
                Kết quả Import:
                <Form.Item label={'Thêm mới:'}>
                  {dataAfterImported.countAdded} thành công, {dataAfterImported.addedErrorList.length} thất bại
                </Form.Item>
              </>
            }
          </Col>
          {/*<Col style={{marginTop: -30}}>*/}
          {/*  {*/}
          {/*    fileImported.imported && <Form.Item label={'Cập nhật:'}>*/}
          {/*      {fileImported.countUpdate} thành công, {fileImported.updatedErrorList.length} thất bại*/}
          {/*    </Form.Item>}*/}
          {/*</Col>*/}
          <Col>
            {
              dataChecked.fileToImport &&
              <>
                Ngày hiệu lực:
                <DatePicker
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return current && current < moment(customDate, "YYYY-MM-DD");
                  }}
                  defaultValue={dayjs()}
                  onChange={(date, dateString) => setImportingValidDate(date)}
                />
              </>
            }
          </Col>
          <Form.Item>
            <Button
              key="submit"
              disabled={checkingFile ||
                executingImportFile ||
                dataChecked.fileToImport.length === 0 ||
                dataChecked.addedErrorList.length !== 0
                // fileToImport.updatedErrorList.length !== 0
              }
              style={{backgroundColor: "#894bf5", borderColor: "#894bf5"}}
              type="primary"
              block
              loading={executingImportFile}
              onClick={() => {
                form.submit();
              }}
            >Xác nhận import</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default PriceContractorList;
