import React, { useState, useEffect } from "react";
import {
  Table,
  Space,
  Button,
  Row,
  Input,
  Typography,
  DatePicker,
  Col,
  Select,
  Tooltip,
  Modal,
  Form,
  Popconfirm,
} from "antd";
import API from "../../../../shared/api/api";
import { dateFormat } from "../../../../shared/dataFormat";
import {
  DeleteOutlined,
  ReloadOutlined,
  MinusOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  EditOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {ROUTE_STATUS_OPTIONS, TRUCK_VENDOR_TYPE} from "../../../../shared/define";
import moment from "moment";
import { AnyARecord } from "dns";
import { getAllBOT } from "../../../../shared/api/BOT";
import { updateCY } from "../../../../shared/api/Route";
import {getAllTruckVendor} from "../../../../shared/api/TruckVendor";

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

function ShippingOrderList() {
  const [form] = Form.useForm();
  const [dataList, setDataList] = useState([]);
  const [forwarderList, setForwarderList] = useState([]);
  const [truckVendorList, setTruckVendorList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
    total: 0,
  });
  const [bot, setBot] = useState<any>([]);
  const [incurredModal, setIncurredModal] = useState({
    visible: false,
    data: [],
    orderId: "",
  });
  const [type, setType] = useState<any>([]);
  const [numberOfFeet, setNumberOfFeet] = useState(0);
  const [idIncurredEdit, setIdIncurredEdit] = useState<any>("");

  useEffect(() => {
    fetchData();
  }, [filter, pagination.current]);

  useEffect(() => {
    // getForwarderList();
    fetchTruckVendor();
    getBot();
  }, []);
  const getBot = async () => {
    const res = await getAllBOT();
    if (res) setBot(res.data);
  };
  const fetchData = async () => {
    setLoading(true);
    const response: any = await API.get("admin/shipping-order", {
      params: {
        ...filter,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
    }).then((res) => res.data);
    if (response.success) {
      const data = response.data;
      data.map((item: any, index: any) => {
        item.key = index + 1;
      });
      setDataList(data);
      setPagination({ ...pagination, total: response.totalItem });
    }
    setLoading(false);
  };

  const getForwarderList = () => {
    API.get("admin/forwarder/all")
      .then((res) => {
        if (res.data.success) {
          setForwarderList(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchTruckVendor = async () => {
    const response: any = await getAllTruckVendor();
    if (response.data) {
      const list = response.data.sort((a: any, b: any) => a.shortName.localeCompare(b.shortName));
      setTruckVendorList(list);
    }
  };

  const finishOrder = (id: string) => {
    API.put(`/admin/route/${id}`, { updateStatus: "FINISH" })
      .then((res) => {
        if (res.data.success) {
          fetchData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addIncurreds = (values: any) => {
    API.post("admin/incurred", { ...values, order: incurredModal.orderId })
      .then((res) => {
        if (res.data.success) {
          closeIncurredModal();
          fetchData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateIncurreds = (values: any) => {
    API.put(`admin/incurred/${idIncurredEdit}`, { ...values.incurreds?.[0] })
      .then((res) => {
        if (res.data.success) {
          closeIncurredModal();
          fetchData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const closeIncurredModal = () => {
    setIncurredModal({ ...incurredModal, visible: false });
    setType([]);
    setNumberOfFeet(0);
    setIdIncurredEdit("");
    form.setFieldsValue({ incurreds: [] });
  };

  const columns: Array<object> = [
    {
      title: "STT",
      dataIndex: "key",
      width: 50,
      render: (key: any, row: any) => <Tooltip title={row.code}>{key}</Tooltip>,
    },
    {
      title: "Nhà xe",
      dataIndex: ["truckVendor", "shortName"],
    },
    {
      title: "Ngày",
      dataIndex: "receipt",
      render: (receipt: any) => moment(receipt[0].time).format("DD/MM/YYYY"),
    },
    {
      title: "Deadline",
      dataIndex: "delivery",
      render: (delivery: any) => dateFormat(delivery?.[0].time),
    },
    {
      title: "Xe đang đi",
      dataIndex: ["routes", "vehicle", "licensePlate"],
      render: (licensePlate: any) =>
        licensePlate ? licensePlate : "Chưa lấy hàng",
    },
    {
      title: "Tài xế",
      dataIndex: ["routes", "driver", "name"],
      render: (name: any) => (name ? name : "Chưa lấy hàng"),
    },
    {
      title: "Container",
      dataIndex: ["vehicle", "container"],
    },
    {
      title: "Tên khách hàng",
      dataIndex: ['customer', 'name'],
    },
    {
      title: "Điểm nhận hàng",
      dataIndex: "receipt",
      // width: 150,
      // ellipsis: true,
      render: (receipt: any) => (
        <Tooltip placement="topLeft" title={receipt?.[0]?.text}>
          <div className="address">{receipt?.[0]?.place?.name}</div>
        </Tooltip>
      ),
    },
    {
      title: "Điểm trả hàng",
      dataIndex: "delivery",
      // width: 150,
      // ellipsis: true,
      render: (delivery: any) => (
        <Tooltip placement="topLeft" title={delivery?.[0]?.text}>
          <div className="address">{delivery?.[0]?.place?.name}</div>
        </Tooltip>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: ["routes", "status"],
      width: 120,
      render: (status: any) => {
        const obj = ROUTE_STATUS_OPTIONS.find(
          (element: any) => (element as any).value === status
        );
        return obj?.label;
      },
    },
    {
      title: "Doanh thu dự kiến",
      dataIndex: "price",
      render: (price: any) => {
        return price ? new Intl.NumberFormat().format(price) + "đ" : "Báo sau"
      }
    },
    {
      title: "Chi phí dự kiến",
      // dataIndex: "price",
      render: (order: any) => {
        const amount = order?.truckVendor?.truckVendorType === TRUCK_VENDOR_TYPE.CONTRACTOR ? order.expenseAmount : 0
        return new Intl.NumberFormat().format(amount) + "đ"
      }
    },
    {
      title: "Tỉ lệ kết hợp",
      dataIndex: ["routes", "combinedRate"],
      align: "right",
    },
    {
      title: "CPPS dự kiến",
      dataIndex: "incurreds",
      align: "right",
      render: (incurreds: any, record: any) => {
        let amount = 0;
        incurreds.forEach((incurred: any) => {
          if (incurred.verified && incurred.type === "PLAN")
            amount += incurred.amount;
        });
        return (
          <Space>
            <div>{new Intl.NumberFormat().format(amount)}đ</div>
            {/* <Tooltip title="Cập nhật">
                        <EditOutlined onClick={() => setIncurredModal({...incurredModal, data: incurreds, visible: true, orderId: record._id})} style={{ fontSize: 16, color: '#3fa3ff' }} />
                    </Tooltip> */}
          </Space>
        );
      },
    },
    {
      title: "CPPS thực tế",
      dataIndex: "incurreds",
      align: "right",
      render: (incurreds: any, record: any) => {
        let amount = 0;
        incurreds.forEach((incurred: any) => {
          if (incurred.verified && incurred.type === "ACTUAL")
            amount += incurred.amount;
        });
        return (
          <Space>
            <div>{new Intl.NumberFormat().format(amount)}đ</div>
            <Tooltip title="Cập nhật">
              <EditOutlined
                onClick={() => {
                  setNumberOfFeet(record?.routes?.vehicle?.type?.numberOfFeets);
                  setIncurredModal({
                    ...incurredModal,
                    data: incurreds,
                    visible: true,
                    orderId: record._id,
                  });
                }}
                style={{ fontSize: 16, color: "#3fa3ff" }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: "Kết hợp CY",
      align: "center",
      dataIndex: ["routes", "combinedRate"],
      render: (route: any, record: any) =>
        route != "CY" && (
          <Select
            defaultValue="No"
            bordered={false}
            onChange={(value) => handleOnChangeCY(value, record.routes._id)}
          >
            <Option value="No">Không</Option>
            <Option value="CY">CY</Option>
          </Select>
        ),
    },
    {
      title: "Tác vụ",
      align: "center",
      render: (order: any) => (
        <Space size="middle">
          <Popconfirm
            placement="topLeft"
            title={"Bạn có chắc muốn hoàn thành đơn?"}
            // description={'description'}
            onConfirm={() => {
              finishOrder(order?.routes?._id);
            }}
            okText="Có"
            cancelText="Không"
          >
            <Button
              icon={
                <CheckCircleOutlined style={{ color: "green", fontSize: 16 }} />
              }
              shape="circle"
            ></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const handleOnChangeCY = async (value: any, id: any) => {
    if (value == "CY") {
      const res = await updateCY(id);
      if (res) fetchData();
    }
  };
  const incurredColumns = [
    {
      title: "STT",
      dataIndex: "_id",
      width: 50,
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "title",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      render: (description: any) => (description ? description : "Không có"),
    },
    {
      title: "Giá tiền",
      dataIndex: "amount",
      render: (amount: any) =>
        (amount ? new Intl.NumberFormat().format(amount) : 0) + " đ",
    },
    {
      title: "Loại",
      dataIndex: "type",
      render: (type: any) => (type === "PLAN" ? "Dự kiến" : "Thực tế"),
    },
    {
      title: "Dạng chi phí",
      dataIndex: "source",
      render: (type: any) =>
        type === "DEFAULT" ? "Mặc định" : "Trạm cầu đường",
    },
    {
      title: "Trạng thái",
      dataIndex: "verified",
      render: (verified: any) => (verified ? "Đã duyệt" : "Chưa duyệt"),
    },
    {
      title: "Tác vụ",
      render: (item: any) => (
        <Space size="middle">
          <Tooltip title="Xóa">
            <DeleteOutlined
              style={{ fontSize: 16, color: "red" }}
              onClick={() => {
                deleteIncurreds(item._id);
              }}
            />
          </Tooltip>
          <Tooltip title="Sửa">
            <EditOutlined
              style={{ fontSize: 16, color: "#3fa3ff" }}
              onClick={() => {
                {
                  setIdIncurredEdit(item._id);
                  form.setFieldsValue({
                    incurreds: [
                      { ...item, verified: item.verified?.toString() },
                    ],
                  });
                }
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  const onChangeType = (index: any, value: any) => {
    const clonetype = [...type];
    const cloneStage = [...form.getFieldValue("incurreds")];
    clonetype[index] = value;
    if (value == "BOT") {
      cloneStage[index].verified = "true";
      form.setFieldsValue({ incurreds: cloneStage });
    }
    setType(clonetype);
  };
  const onDeleteType = (index: any) => {
    const clonetype = [...type];
    clonetype.splice(index, 1);
    setType(clonetype);
  };
  const addType = () => {
    const clonetype = [...type];
    clonetype.push("DEFAULT");
    setType(clonetype);
  };
  const onChangeBot = (index: any, value: any) => {
    const cloneIncurred = [...form.getFieldValue("incurreds")];
    const found = bot.find((element: any) => element._id == value);
    cloneIncurred[index].title = found.name;
    if (numberOfFeet == 20) {
      cloneIncurred[index].amount = found.turn.twenty;
    }
    if (numberOfFeet == 40) {
      cloneIncurred[index].amount = found.turn.forty;
    }
    form.setFieldsValue({ incurreds: cloneIncurred });
  };
  const deleteIncurreds = (id: any) => {
    API.delete(`admin/incurred/${id}`)
      .then((res) => {
        if (res.data.success) {
          const dataSource = [...incurredModal.data];
          setIncurredModal({
            ...incurredModal,
            data: dataSource.filter((item: any) => item._id !== id),
          });
          fetchData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Title level={4}>
        Đơn đang đi ({pagination.total}){" "}
        <Tooltip title="Danh sách những đơn đang chờ vận chuyển và đang được vận chuyển. Hiển thị thông tin tài xế và xe trong trường hợp hàng đã được nhận">
          <InfoCircleOutlined style={{ color: "#0082fb" }} />
        </Tooltip>
      </Title>
      <Row justify="space-between" gutter={6}>
        <Col>
          <Space size={6}>
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              size="small"
              style={{ width: 220 }}
              placeholder="Nhà xe"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={(value) => setFilter({ ...filter, truckVendor: value })}
            >
              <Option value="ALL">Tất cả</Option>
              {truckVendorList.map((truckVendor: any) => (
                <Option key={truckVendor._id} value={truckVendor._id}>
                  {truckVendor?.shortName}
                </Option>
              ))}
            </Select>

            <Select
              placeholder="Loại hàng"
              size="small"
              style={{ width: 120 }}
              onChange={(value) =>
                setFilter({ ...filter, preservation: value })
              }
            >
              <Option value="ALL">Tất cả</Option>
              <Option value="NORMAL">Thường</Option>
              <Option value="COLD">Lạnh</Option>
              <Option value="DANGER">Nguy hiểm</Option>
              <Option value="OOG">Quá khổ</Option>
            </Select>

            <RangePicker
              size="small"
              style={{ width: 250 }}
              onChange={(date, dateString) =>
                setFilter({
                  ...filter,
                  range: [
                    moment.utc(date?.[0]).format("YYYY-MM-DD HH:mm:ssZ"),
                    moment.utc(date?.[1]).format("YYYY-MM-DD HH:mm:ssZ"),
                  ],
                })
              }
            />

            <Select
              placeholder="Loại container"
              size="small"
              style={{ width: 150 }}
              onChange={(value) =>
                setFilter({ ...filter, numberOfFeets: value })
              }
            >
              <Option value="ALL">Tất cả</Option>
              <Option value="20">20</Option>
              <Option value="40">40</Option>
              <Option value="45">45</Option>
            </Select>
            <Select
              placeholder="Nhập/Xuất/Bộ"
              size="small"
              style={{ width: 150 }}
              onChange={(value) =>
                setFilter({ ...filter, specification: value })
              }
            >
              <Option value="ALL">Tất cả</Option>
              <Option value="IMPORT">Nhập</Option>
              <Option value="EXPORT">Xuất</Option>
              <Option value="ROAD">Bộ</Option>
            </Select>
          </Space>
        </Col>
        <Space size={6}>
          <Button
            size="small"
            onClick={fetchData}
            icon={<ReloadOutlined />}
          ></Button>
          <Search
            placeholder="Số container"
            // disabled
            allowClear
            size="small"
            style={{ width: 200, margin: "0 10px" }}
            onSearch={(value) => setFilter({ ...filter, searchText: value })}
          />
        </Space>
      </Row>
      <br />
      <Table
        scroll={{ x: "max-content" }}
        loading={loading}
        columns={columns}
        dataSource={dataList}
        pagination={pagination.total > 10 && pagination}
        onChange={(value: any) =>
          setPagination({ ...pagination, current: value.current })
        }
      />
      <Modal
        width={800}
        destroyOnClose={true}
        closable={true}
        title="Chi phí phát sinh"
        visible={incurredModal.visible}
        onCancel={closeIncurredModal}
        onOk={() => {
          form.submit();
        }}
      >
        <Table
          bordered={false}
          columns={incurredColumns}
          dataSource={incurredModal.data}
          pagination={false}
        />
        <br />
        <Form
          {...layout}
          form={form}
          onFinish={idIncurredEdit ? updateIncurreds : addIncurreds}
        >
          <Form.List name="incurreds">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Row
                    style={{
                      borderBottom: "1px solid #eee",
                      marginBottom: 20,
                      padding: 10,
                    }}
                    gutter={8}
                  >
                    <Col span={8}>
                      <Form.Item
                        label="Tên"
                        name={[field.name, "title"]}
                        rules={[
                          {
                            required: true,
                            message: "Tên phát sinh là bắt buộc",
                          },
                        ]}
                      >
                        <Input
                          {...(type[field.name] == "BOT" && { disabled: true })}
                          placeholder="Tên phát sinh"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Chi phí"
                        name={[field.name, "amount"]}
                        rules={[
                          { required: true, message: "Chi phí là bắt buộc" },
                        ]}
                      >
                        <Input
                          {...(type[field.name] == "BOT" && { disabled: true })}
                          placeholder="Số tiền"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Dạng chi phí"
                        name={[field.name, "source"]}
                        initialValue="DEFAULT"
                      >
                        <Select
                          onChange={(value: any) =>
                            onChangeType(field.name, value)
                          }
                        >
                          <Option value="BOT">Trạm cầu đường</Option>
                          <Option value="DEFAULT">Mặc định</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Loại"
                        name={[field.name, "type"]}
                        initialValue="ACTUAL"
                      >
                        <Select disabled>
                          <Option value="PLAN">Dự kiến</Option>
                          <Option value="ACTUAL">Thực tế</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {type[field.name] == "BOT" && (
                      <Col span={8}>
                        <Form.Item
                          label="Trạm thu phí"
                          // name={[field.name, 'bot']}
                          rules={[
                            {
                              required: true,
                              message: "Trạm thu phí là bắt buộc",
                            },
                          ]}
                        >
                          <Select
                            onChange={(value: any) =>
                              onChangeBot(field.name, value)
                            }
                          >
                            {bot.map((item: any, index: any) => (
                              <Option key={index} value={item._id}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    <Col span={8}>
                      <Form.Item
                        label="Trạng thái"
                        name={[field.name, "verified"]}
                        initialValue="true"
                      >
                        <Select
                          {...(type[field.name] == "BOT" && { disabled: true })}
                        >
                          <Option value="true">Duyệt</Option>
                          <Option value="false">Không duyệt</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {type[field.name] == "DEFAULT" && (
                      <Col span={8}>
                        <Form.Item
                          label="Mô tả"
                          name={[field.name, "description"]}
                        >
                          <Input.TextArea rows={1} placeholder="Mô tả" />
                        </Form.Item>
                      </Col>
                    )}

                    <Col span={24}>
                      <Form.Item>
                        <Button
                          danger
                          type="dashed"
                          onClick={() => {
                            remove(field.name);
                            onDeleteType(field.name);
                            if (idIncurredEdit) setIdIncurredEdit("");
                          }}
                          block
                          icon={<MinusOutlined />}
                        ></Button>
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
                {!idIncurredEdit && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                        addType();
                      }}
                      block
                      icon={<PlusOutlined />}
                    ></Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
}

export default ShippingOrderList;
