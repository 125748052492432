import React, { useEffect, useMemo, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Upload,
  Typography,
  Table,
  Select,
  Space,
  Spin,
  Card,
  InputNumber,
  Modal,
  AutoComplete,
} from "antd";
import {
  LeftOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  EditOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import {
  rule_bigger_than_5b,
  rule_float_number,
  rule_number,
  rule_phoneNumber,
  rule_require,
} from "../../../../shared/validateInput";
import {
  beforeUpload,
  onPreview,
  propsImage,
} from "../../../../shared/imageHandle";
import {
  COMBINE_RATE,
  COMBINE_TYPE,
  DANGER_OPTION,
  DELIVERY_TYPE,
  FORBIDDEN_OPTIONS,
  INSURANCE_VALUE_OPTION,
  NUMBER_OF_AXLES_OPTION,
  NUMBER_OF_FEETS_OPTIONS,
  ORIGIN_OPTION,
  PAYLOAD_OPTION,
  PRESERVATION_OPTIONS,
  SPECIFICATION_TYPE,
  VEHICLE_OPTION,
} from "../../../../shared/define";
import {
  getPendingOrder,
  updatePendingOrder,
} from "../../../../shared/api/Order/PendingOrder";
import { getListProvince } from "../../../../shared/api/Province";
import { getAllForwarder } from "../../../../shared/api/Forwarder";
import { getListArea } from "../../../../shared/api/Freight";
import { getAllCustomer } from "../../../../shared/api/Customer";
import { getAllIncurred } from "../../../../shared/api/Incurred";
import { getAllShip } from "../../../../shared/api/Ship";
import { searchPlaces } from "../../../../shared/api/Route";
import _ from "lodash";
import API from "../../../../shared/api/api";
import MomentDatePicker from "../../../../Components/MomentDatePicker";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Option } = Select;
const { Title, Text } = Typography;
function UpdateOrder() {
  let { id }: any = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [formIncurred] = Form.useForm();
  const [cityList, setCityList] = useState<any>([]);
  const [fileAvatarList, setFileAvatarList] = useState<any>([]);
  const [forwarderList, setForwarderList] = useState<any>([]);
  const [customerList, setCustomerList] = useState<any>([]);
  const [shipList, setShipList] = useState<any>([]);
  const [vehicleType, setVehicleType] = useState<any>("");
  const [spinning, setSpinning] = useState(true);
  const [isLoadingBtn, setLoadingBtn] = useState(false);

  const [receiptProvinceList, setReceiptProvinceList] = useState<any>([]);
  const [receiptAreaList, setReceiptAreaList] = useState<any>([]);

  const [deliveryProvinceList, setDeliveryProvinceList] = useState<any>([]);
  const [deliveryAreaList, setDeliveryAreaList] = useState<any>([]);

  const [combine, setCombine] = useState("");
  const [goodValue, setGoodValue] = useState("");
  const [preservation, setPreservation] = useState("");
  const [incurredModal, setIncurredModal] = useState({
    visible: false,
    data: [],
    orderId: "",
  });
  const [combineRate, setCombineRate] = useState("");

  const [incurredTotal, setIncurredTotal] = useState("");
  const [receiptDistrictName, setReceiptDistrictName] = useState("");
  const [receiptProvinceName, setReceiptProvinceName] = useState("");
  const [deliveryDistrictName, setDeliveryDistrictName] = useState("");
  const [deliveryProvinceName, setDeliveryProvinceName] = useState("");
  const [combinations, setCombinations] = useState<any[]>([]);
  const [receiptId, setReceiptId] = useState("");
  const [deliveryId, setDeliveryId] = useState("");
  const [customerId, setCustomerId] = useState("");

  console.log("receiptIdreceiptId", receiptId, deliveryId);

  const fetchFreightCombination = async () => {
    const searchParams = {
      receipt: receiptId,
      delivery: deliveryId,
      customer: customerId,
    };
    const response: any = await API.get("admin/freight/combination", {
      params: searchParams,
    });
    const { data } = response;

    if (data.success) {
      setCombinations(data?.data);
    }
  };

  useEffect(() => {
    if (receiptId && deliveryId) {
      fetchFreightCombination();
    }
  }, [receiptId, deliveryId]);

  useEffect(() => {
    fetchListProvince();
    fetchForwarderList();
    fetchCustomerList({});
    fetchShipList({});
    loadData();
  }, []);
  const fetchShipList = async (param: any) => {
    const response: any = await getAllShip(param);
    setShipList(response.data);
  };
  const loadData = async () => {
    setSpinning(true);
    const response = await getPendingOrder(id);
    if (response.success) {
      setReceiptDistrictName(response.data?.receipt?.[0]?.area?.name);
      setReceiptProvinceName(response.data?.receipt?.[0]?.area?.pName);
      setDeliveryDistrictName(response.data?.delivery?.[0]?.area?.name);
      setDeliveryProvinceName(response.data?.delivery?.[0]?.area?.pName);
      setReceiptId(response.data?.receipt?.[0]?.area?._id);
      setDeliveryId(response.data?.delivery?.[0]?.area?._id);
      converData(response.data);
    }
    setSpinning(false);
  };
  const converData = (data: any) => {
    let listData = data;
    let imageArray: any[] = [];
    for (let i = 0; i < listData.goods?.avatars.length; i++) {
      imageArray.push({
        uid: i,
        name: i,
        url: listData.goods.avatars[i],
        thumbUrl: listData.goods.avatars[i],
        response: { data: { url: listData.goods.avatars[i] } },
      });
    }
    setFileAvatarList(imageArray);
    setVehicleType(listData.vehicle.name);
    for (let i = 0; i < listData.delivery.length; i++) {
      listData.delivery[i] = {
        ...listData.delivery[i],
        time: moment(listData.delivery[i].time),
      };
      fetchListArea(listData.delivery[i].area.province, i, "DELIVERY");
    }
    for (let i = 0; i < listData.receipt.length; i++) {
      listData.receipt[i] = {
        ...listData.receipt[i],
        time: moment(listData.receipt[i].time),
      };
      fetchListArea(listData.receipt[i].area.province, i, "RECEIPT");
    }
    let amount = 0;
    listData.incurreds?.forEach((incurred: any) => {
      if (incurred.verified && incurred.type === "PLAN")
        amount += incurred.amount;
    });
    setIncurredTotal(new Intl.NumberFormat().format(amount));
    form.setFieldsValue(listData);
    setPreservation(form.getFieldValue(["goods", "preservation"]));
    setGoodValue(form.getFieldValue(["goods", "value"]));
    setCombine(form.getFieldValue("combinedType"));
  };
  const fetchForwarderList = async () => {
    const response: any = await getAllForwarder();
    setForwarderList(response.data);
  };
  const fetchCustomerList = async (param: any) => {
    const response: any = await getAllCustomer(param);
    setCustomerList(response.data);
  };
  const fetchListProvince = async () => {
    const response: any = await getListProvince();
    setCityList(response.results);
  };
  const fetchListArea = async (
    province: string,
    index: number,
    type: string
  ) => {
    const param = { params: { province: province } };
    const response: any = await getListArea(param);
    if (response.success) {
      let listData = response.data;
      let customData = [];
      for (let i = 0; i < listData.length; i++) {
        customData.push({ area: listData[i].area, _id: listData[i]._id });
      }
      if (type === "RECEIPT") {
        let arr = [...receiptAreaList];
        arr[index] = customData;
        setReceiptAreaList(arr);
      }
      if (type === "DELIVERY") {
        let arr = [...deliveryAreaList];
        arr[index] = customData;
        setDeliveryAreaList(arr);
      }
    }
  };
  const onUpdate = async () => {
    setLoadingBtn(true);
    let imageArray: any[] = [];
    for (let i = 0; i < fileAvatarList.length; i++) {
      imageArray.push(fileAvatarList[i].response?.data.url);
    }
    const customData = {
      ...form.getFieldsValue(),
      price: form.getFieldsValue().price?.toString(),
      goods: {
        ...form.getFieldsValue().goods,
        avatars: imageArray,
      },
      freight: form.getFieldValue(["freight", "_id"]),
      forwarder: {
        _id: form.getFieldValue("forwarder"),
      },
    };
    for (let i = 0; i < customData.delivery.length; i++) {
      customData.delivery[i] = {
        ...customData.delivery[i],
        time: moment
          .utc(customData.delivery[i].time)
          .format("YYYY-MM-DD HH:mm:ssZ"),
        area: customData.delivery[i].area?._id,
      };
    }
    for (let i = 0; i < customData.receipt.length; i++) {
      customData.receipt[i] = {
        ...customData.receipt[i],
        time: moment
          .utc(customData.receipt[i].time)
          .format("YYYY-MM-DD HH:mm:ssZ"),
        area: customData.receipt[i].area?._id,
      };
    }

    const response = await updatePendingOrder({ id: id, data: customData });
    await addIncurreds();
    if (response.success) history.push("/order/pending");
    setLoadingBtn(false);
  };
  const onRemoveFormList = (index: number, type: string) => {
    if (type === "RECEIPT") {
      const provinceArr = [...receiptProvinceList];
      const areaArr = [...receiptAreaList];
      provinceArr.splice(index, 1);
      areaArr.splice(index, 1);
      setReceiptAreaList(areaArr);
      setReceiptProvinceList(provinceArr);
    }
    if (type === "DELIVERY") {
      const provinceArr = [...deliveryProvinceList];
      const areaArr = [...deliveryAreaList];
      provinceArr.splice(index, 1);
      areaArr.splice(index, 1);
      setDeliveryAreaList(areaArr);
      setDeliveryProvinceList(provinceArr);
    }
  };
  const isEmtyFormList = (type: string) => {
    if (form.getFieldValue(type).length <= 1) {
      return true;
    } else {
      return false;
    }
  };
  const addIncurreds = async () => {
    if (incurredModal.data.length != 0) {
      const response: any = await getAllIncurred({
        incurreds: incurredModal.data,
        order: incurredModal.orderId,
      });
      if (response.success) {
        setIncurredModal({ ...incurredModal, visible: false });
      } else return;
    }
  };

  const closeIncurredModal = () => {
    setIncurredModal({ ...incurredModal, visible: false });
    formIncurred.setFieldsValue({ incurreds: [] });
  };
  const incurredColumns = [
    {
      title: "STT",
      dataIndex: "_id",
      width: 50,
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "title",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      render: (description: any) => (description ? description : "Không có"),
    },
    {
      title: "Giá tiền",
      dataIndex: "amount",
      render: (amount: any) =>
        (amount ? new Intl.NumberFormat().format(amount) : 0) + " đ",
    },
    {
      title: "Loại",
      dataIndex: "type",
      render: (type: any) => (type === "PLAN" ? "Dự kiến" : "Thực tế"),
    },
    {
      title: "Trạng thái",
      dataIndex: "verified",
      render: (verified: any) =>
        verified === true ? "Đã duyệt" : "Chưa duyệt",
    },
  ];

  const [options, setOptions] = React.useState([]);

  const handleSearch = async (txt: string) => {
    if (txt) {
      const response: any = await searchPlaces(txt);
      if (response) {
        if (response.data) {
          const result = response.data.map((item: any) => {
            return {
              label: `${item.name}, ${item.address}`,
              value: `${item.name}, ${item.address}`,
              item: item,
            };
          });
          setOptions(result);
        }
      }
    }
  };

  const debouncedChangeHandler = useMemo(
    () => _.debounce((userInput) => handleSearch(userInput), 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const onSearch = (text: string) => {
    debouncedChangeHandler(text);
  };

  return (
    <div
      style={{
        display: "flex",
        padding: 12,
        width: "100%",
        backgroundColor: "#F0F2F5",
      }}
    >
      <Row style={{ width: "100%" }}>
        <Spin spinning={spinning}>
          <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
            <span
              onClick={() => {
                history.goBack();
              }}
            >
              <LeftOutlined />
              Quay lại
            </span>
          </Title>
          <Form
            form={form}
            {...layout}
            style={{ width: "100%" }}
            onFinish={onUpdate}
            onFinishFailed={() => {
              setLoadingBtn(false);
            }}
          >
            <Row gutter={16}>
              <Col
                span={24}
                style={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  marginBottom: 24,
                  padding: "12px 12px 0 12px ",
                }}
              >
                <Title level={5} style={{ marginBottom: 8 }}>
                  Thông tin khách hàng
                </Title>
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item
                      label="Chủ hàng"
                      name="forwarder"
                      rules={[rule_require]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Chọn forwarder"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onSelect={(value: any) =>
                          fetchCustomerList({ forwarder: value })
                        }
                      >
                        {forwarderList.map((forwarder: any) => (
                          <Option key={forwarder._id} value={forwarder._id}>
                            {forwarder.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Tên khách hàng"
                      name={["customer", "_id"]}
                    >
                      <Select
                        onChange={(e) => {
                          setCustomerId(e);
                        }}
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Chọn khách hàng"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {customerList.map((cus: any) => (
                          <Option key={cus._id} value={cus._id}>
                            {cus.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Tên hãng tàu"
                      name={["shippingLines", "_id"]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Chọn hãng tàu"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {shipList.map((ship: any) => (
                          <Option key={ship._id} value={ship._id}>
                            {ship.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col
                span={24}
                style={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  marginBottom: 24,
                  padding: "12px 12px 0 12px ",
                }}
              >
                <Title level={5} style={{ marginBottom: 8 }}>
                  Thông tin vận chuyển
                </Title>
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item
                      label="Số xe cần chở"
                      name={["vehicle", "number"]}
                      rules={[rule_require, rule_number]}
                    >
                      <Input placeholder="Nhập số xe cần chở" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Loại xe"
                      name={["vehicle", "name"]}
                      rules={[rule_require]}
                    >
                      <Select
                        placeholder="Chọn loại xe"
                        onSelect={(type: string) => setVehicleType(type)}
                      >
                        {VEHICLE_OPTION.map((item: any, index: any) => (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {vehicleType === VEHICLE_OPTION[0].value && (
                    <Col span={6}>
                      <Form.Item
                        label="Loại Container"
                        name={["vehicle", "numberOfFeets"]}
                        rules={[rule_require]}
                      >
                        <Select placeholder="Chọn Loại Container">
                          {NUMBER_OF_FEETS_OPTIONS.map(
                            (item: any, index: any) => (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  {vehicleType === VEHICLE_OPTION[0].value && (
                    <Col span={6}>
                      <Form.Item
                        label="Số cont"
                        name={["vehicle", "container"]}
                      >
                        <Input placeholder="Nhập số container" />
                      </Form.Item>
                    </Col>
                  )}
                  {vehicleType === VEHICLE_OPTION[1].value && (
                    <Col span={6}>
                      <Form.Item
                        label="Tải trọng"
                        name={["vehicle", "payload"]}
                        rules={[rule_require]}
                      >
                        <Select placeholder="Chọn tải trọng">
                          {PAYLOAD_OPTION.map((item: any, index: any) => (
                            <Option key={index} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col
                span={24}
                style={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  marginBottom: 24,
                  padding: "12px 12px 0 12px ",
                }}
              >
                <Title level={5} style={{ marginBottom: 8 }}>
                  Thông tin hàng hóa
                </Title>
                <Row gutter={16}>
                  <Col span={6}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Ảnh hàng (Không bắt buộc)"
                          name={["goods", "avatars"]}
                          extra={
                            <Space direction="vertical">
                              <Text>Ảnh không quá 1mb</Text>
                              <Text>Size 200x200px</Text>
                            </Space>
                          }
                        >
                          <ImgCrop rotate>
                            <Upload
                              listType="picture-card"
                              {...propsImage}
                              fileList={fileAvatarList}
                              onChange={({
                                fileList: newFileList,
                              }: {
                                fileList: any;
                              }) => {
                                setFileAvatarList(newFileList);
                              }}
                              onPreview={onPreview}
                              beforeUpload={beforeUpload}
                            >
                              {fileAvatarList.length < 3 && "+ Upload"}
                            </Upload>
                          </ImgCrop>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={18}>
                    <Row gutter={16}>
                      <Col span={6}>
                        <Form.Item
                          label="Tên hàng"
                          name={["goods", "name"]}
                          rules={[rule_require]}
                        >
                          <Input placeholder="Nhập tên hàng" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Khối lượng"
                          name={["goods", "volume"]}
                          rules={[rule_require, rule_number]}
                        >
                          <Input
                            placeholder="Nhập khối lượng hàng"
                            suffix="tấn"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Giá trị đơn hàng"
                          name={["goods", "value"]}
                          rules={[rule_require]}
                        >
                          <Select
                            placeholder="Chọn giá trị đơn hàng"
                            onChange={(value: string) => setGoodValue(value)}
                          >
                            {INSURANCE_VALUE_OPTION.map(
                              (item: any, index: any) => (
                                <Option key={index} value={item.value}>
                                  {item.label}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      {goodValue === INSURANCE_VALUE_OPTION[0].value && (
                        <Col span={6}>
                          <Form.Item
                            label="Giá trị chính xác"
                            name={["goods", "valueNumber"]}
                            rules={[rule_bigger_than_5b]}
                          >
                            <InputNumber
                              placeholder="Nhập số tiền"
                              formatter={(value) =>
                                ` ${value
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} đ`
                              }
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col span={6}>
                        <Form.Item
                          label="Loại hàng"
                          name={["goods", "origin"]}
                          rules={[rule_require]}
                        >
                          <Select placeholder="Chọn loại hàng">
                            {ORIGIN_OPTION.map((item: any, index: any) => (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Quy cách"
                          name="specification"
                          // rules={[rule_require]}
                        >
                          <Select placeholder="Chọn quy cách">
                            {SPECIFICATION_TYPE.map((item: any, index: any) => (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Tính chất hàng hóa"
                          name={["goods", "preservation"]}
                          rules={[rule_require]}
                        >
                          <Select
                            placeholder="Chọn tính chất hàng hóa"
                            onSelect={(value: string) => setPreservation(value)}
                          >
                            {PRESERVATION_OPTIONS.map(
                              (item: any, index: any) => (
                                <Option key={index} value={item.value}>
                                  {item.label}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      {preservation == PRESERVATION_OPTIONS[2].value && (
                        <Col span={6}>
                          <Form.Item
                            label="Mức độ nguy hiểm"
                            name={["goods", "dangerLevel"]}
                            rules={[rule_require]}
                          >
                            <Select placeholder="Chọn mức độ nguy hiểm">
                              {DANGER_OPTION.map((item: any, index: any) => (
                                <Option key={index} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                      <Col span={6}>
                        <Form.Item
                          label="Loại kết hợp"
                          name="combinedType"
                          // rules={[rule_require]}
                        >
                          <Select
                            placeholder="Chọn loại kết hợp"
                            onSelect={(value: any) => setCombine(value)}
                          >
                            {COMBINE_TYPE.map((item: any, index: any) => (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      {combine === COMBINE_TYPE[0].value && (
                        <Col span={6}>
                          <Form.Item
                            label="Chỉ số kết hợp"
                            name="combinedRate"
                            // rules={[rule_float_number]}
                          >
                            <Select
                              {...(combineRate != "CY" && { suffixIcon: "%" })}
                              onChange={(value: any) => setCombineRate(value)}
                            >
                              {COMBINE_RATE.map((item: any) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                      <Col span={6}>
                        <Form.Item
                          label="Yêu cầu kĩ thuật xe"
                          name={["goods", "technicalRequirement"]}
                          // rules={[rule_require]}
                        >
                          <Input.TextArea
                            placeholder="Nhập yêu cầu kĩ thuật"
                            rows={1}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Lưu ý khi đóng (nhận) hàng"
                          name={["goods", "packingInstruction"]}
                          // rules={[rule_require]}
                        >
                          <Input.TextArea
                            placeholder="Nhập Lưu ý khi đóng (trả) hàng"
                            rows={1}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Giá cước"
                          name="price"
                          rules={[rule_number]}
                        >
                          <InputNumber
                            placeholder="hệ thống tính toán nếu không nhập"
                            formatter={(value) =>
                              ` ${value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                            }
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="CPPS Dự kiến" rules={[rule_number]}>
                          <Input
                            value={incurredTotal}
                            style={{ width: "100%" }}
                            suffix={
                              <EditOutlined
                                onClick={() => {
                                  setIncurredModal({
                                    ...incurredModal,
                                    data: form.getFieldValue("incurreds"),
                                    visible: true,
                                    orderId: id,
                                  });
                                }}
                                style={{ fontSize: 16, color: "#3fa3ff" }}
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="Số chì" name="seal">
                          <Input placeholder="Nhập số chì" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Hình thức giao nhận"
                          name="deliveryForm"
                        >
                          <Select placeholder="Chọn hình thức giao nhận">
                            {DELIVERY_TYPE.map((item: any, index: any) => (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <Title level={5} style={{ color: "rgba(3, 169, 244, 1)" }}>
                  Danh sách điểm nhận hàng
                </Title>
              </Col>
              <Form.List name="receipt">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field: any, index: number) => (
                      <Card
                        key={field.key}
                        style={{ margin: 0, position: "relative" }}
                      >
                        {!isEmtyFormList("receipt") && (
                          <span
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                              zIndex: 1,
                            }}
                          >
                            <CloseCircleOutlined
                              style={{ color: "#f5222d", fontSize: "1.25rem" }}
                              onClick={() => {
                                remove(field.name);
                                onRemoveFormList(field.name, "RECEIPT");
                              }}
                            />
                          </span>
                        )}
                        <Row
                          gutter={[32, 0]}
                          style={{ marginLeft: "0", marginRight: "0" }}
                        >
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                              label="Địa chỉ "
                              // name={[field.name, 'text']}
                              // fieldKey={[field.fieldKey, 'text']}
                              rules={[rule_require]}
                            >
                              <AutoComplete
                                options={options}
                                style={{ width: "100%" }}
                                onSearch={onSearch}
                                defaultValue={form.getFieldValue([
                                  "receipt",
                                  index,
                                  "text",
                                ])}
                                onChange={(value, option: any) => {
                                  setReceiptDistrictName(option?.item?.dName);
                                  setReceiptId(option?.item?.dId);
                                  setReceiptProvinceName(option?.item?.pName);
                                  form.setFields([
                                    {
                                      name: ["receipt", index, "text"],
                                      value: value,
                                    },
                                    {
                                      name: ["receipt", index, "place"],
                                      value: option?.item?._id,
                                    },
                                    {
                                      name: ["receipt", index, "area"],
                                      value: option?.item?.dId,
                                    },
                                  ]);
                                }}
                                placeholder={"Nhập địa chỉ"}
                                filterOption={false}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="Tỉnh/Thành phố">
                              <Input
                                placeholder="Tỉnh/Thành phố"
                                value={receiptProvinceName}
                                disabled
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="Quận/Huyện">
                              <Input
                                disabled
                                placeholder="Quận/Huyện"
                                value={receiptDistrictName}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                              label="Tên người liên hệ"
                              name={[field.name, "name"]}
                              fieldKey={[field.fieldKey, "name"]}
                              // rules={[rule_require]}
                            >
                              <Input placeholder="Nhập tên người liên hệ" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                              label="Số điện thoại"
                              name={[field.name, "phoneNumber"]}
                              fieldKey={[field.fieldKey, "phoneNumber"]}
                              // rules={[rule_require]}
                            >
                              <Input placeholder="Nhập số điện thoại" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={4}>
                            <Form.Item
                              label="Thời gian nhận hàng"
                              name={[field.name, "time"]}
                              fieldKey={[field.fieldKey, "time"]}
                              rules={[rule_require]}
                            >
                              <MomentDatePicker
                                showTime
                                format="DD-MM-YYYY HH"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={4}>
                            <Form.Item
                              label="Đường cấm "
                              name={[field.name, "forbiddenRoad"]}
                              fieldKey={[field.fieldKey, "forbiddenRoad"]}
                              rules={[rule_require]}
                            >
                              <Select
                                placeholder="Chọn loại đường"
                                // defaultValue={'NOT_FORBIDDEN'}
                              >
                                {FORBIDDEN_OPTIONS.map(
                                  (item: any, index: any) => (
                                    <Option key={index} value={item.value}>
                                      {item.label}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          </Col>
                          {/* <Col xs={24} sm={8}>
                            <Form.Item
                              label="Địa chỉ "
                              name={[field.name, 'text']}
                              fieldKey={[field.fieldKey, 'text']}
                              rules={[rule_require]}
                            >
                              <Input.TextArea
                                placeholder="Nhập địa chỉ"
                                rows={1}
                              />
                            </Form.Item>
                          </Col> */}
                        </Row>
                      </Card>
                    ))}
                    <Col
                      xs={24}
                      style={{
                        backgroundColor: "#FAFAFB",
                        padding: "8px 0",
                        cursor: "pointer",
                      }}
                      onClick={() => add()}
                    >
                      <span
                        style={{
                          position: "relative",
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      >
                        <PlusOutlined
                          style={{
                            color: "rgba(3, 169, 244, 1)",
                            fontSize: "1.5rem",
                          }}
                        />
                      </span>
                    </Col>
                  </>
                )}
              </Form.List>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <Title
                  level={5}
                  style={{ color: "rgba(255, 193, 7, 1)", marginTop: 12 }}
                >
                  Danh sách điểm trả hàng
                </Title>
              </Col>
              <Form.List name="delivery">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field: any, index: number) => (
                      <Card
                        key={field.key}
                        style={{ margin: 0, position: "relative" }}
                      >
                        {!isEmtyFormList("delivery") && (
                          <span
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                            }}
                          >
                            <CloseCircleOutlined
                              style={{
                                color: "#f5222d",
                                fontSize: "1.25rem",
                                zIndex: 1,
                              }}
                              onClick={() => {
                                remove(field.name);
                                onRemoveFormList(field.name, "DELIVERY");
                              }}
                            />
                          </span>
                        )}
                        <Row
                          gutter={[32, 0]}
                          style={{ marginLeft: "0", marginRight: "0" }}
                        >
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                              label="Địa chỉ "
                              // name={[field.name, 'text']}
                              // fieldKey={[field.fieldKey, 'text']}
                              rules={[rule_require]}
                            >
                              <AutoComplete
                                options={options}
                                style={{ width: "100%" }}
                                onSearch={onSearch}
                                defaultValue={form.getFieldValue([
                                  "delivery",
                                  index,
                                  "text",
                                ])}
                                // inputValue={defaultValue}
                                onChange={(value, option: any) => {
                                  setDeliveryDistrictName(option?.item?.dName);
                                  setDeliveryProvinceName(option?.item?.pName);
                                  setDeliveryId(option?.item?.dId);

                                  form.setFields([
                                    {
                                      name: ["delivery", index, "text"],
                                      value: value,
                                    },
                                    {
                                      name: ["delivery", index, "place"],
                                      value: option?.item?._id,
                                    },
                                    {
                                      name: ["delivery", index, "area"],
                                      value: option?.item?.dId,
                                    },
                                  ]);
                                }}
                                placeholder={"Nhập địa chỉ"}
                                filterOption={false}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="Tỉnh/Thành phố">
                              <Input
                                placeholder="Tỉnh/Thành phố"
                                value={deliveryProvinceName}
                                disabled
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="Quận/Huyện">
                              <Input
                                disabled
                                placeholder="Quận/Huyện"
                                value={deliveryDistrictName}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                              label="Tên người liên hệ "
                              name={[field.name, "name"]}
                              fieldKey={[field.fieldKey, "name"]}
                              // rules={[rule_require]}
                            >
                              <Input placeholder="Nhập tên người liên hệ" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item
                              label="Số điện thoại"
                              name={[field.name, "phoneNumber"]}
                              fieldKey={[field.fieldKey, "phoneNumber"]}
                              // rules={[rule_require]}
                            >
                              <Input placeholder="Nhập số điện thoại" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={4}>
                            <Form.Item
                              label="Thời gian trả hàng "
                              name={[field.name, "time"]}
                              fieldKey={[field.fieldKey, "time"]}
                              rules={[rule_require]}
                            >
                              <MomentDatePicker
                                showTime
                                format="DD-MM-YYYY HH"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={4}>
                            <Form.Item
                              label="Đường cấm "
                              name={[field.name, "forbiddenRoad"]}
                              fieldKey={[field.fieldKey, "forbiddenRoad"]}
                              rules={[rule_require]}
                            >
                              <Select
                                placeholder="Chọn loại đường"
                                // defaultValue={'NOT_FORBIDDEN'}
                              >
                                {FORBIDDEN_OPTIONS.map(
                                  (item: any, index: any) => (
                                    <Option key={index} value={item.value}>
                                      {item.label}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          </Col>
                          {/* <Col xs={24} sm={8}>
                            <Form.Item
                              label="Địa chỉ "
                              name={[field.name, 'text']}
                              fieldKey={[field.fieldKey, 'text']}
                              rules={[rule_require]}
                            >
                              <Input.TextArea
                                placeholder="Nhập địa chỉ"
                                rows={1}
                              />
                            </Form.Item>
                          </Col> */}
                        </Row>
                      </Card>
                    ))}
                    <Col
                      xs={24}
                      style={{
                        backgroundColor: "#FAFAFB",
                        cursor: "pointer",
                        padding: "8px 0",
                      }}
                      onClick={() => add()}
                    >
                      <span
                        style={{
                          position: "relative",
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      >
                        <PlusOutlined
                          style={{
                            color: "rgba(255, 193, 7, 1)",
                            fontSize: "1.5rem",
                          }}
                        />
                      </span>
                    </Col>
                  </>
                )}
              </Form.List>
              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                <Card
                  key={"freight"}
                  style={{ margin: 0, position: "relative" }}
                >
                  <Form.Item
                    label="Tuyến"
                    name={["freight", "_id"]}
                    rules={[rule_require]}
                  >
                    <Select placeholder="Chọn tuyến">
                      {combinations?.map((item) => {
                        return (
                          <Option
                            value={item._id}
                          >{`[${item?.code}] ${item?.description}`}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Card>
              </Col>
            </Row>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} offset={20}>
              <Button
                loading={isLoadingBtn}
                htmlType="submit"
                style={{
                  width: "100%",
                  backgroundColor: "#03A9F4",
                  color: "white",
                  marginTop: 12,
                }}
              >
                Cập nhật
              </Button>
            </Col>
            <Modal
              width={800}
              destroyOnClose={true}
              closable={true}
              title="Chi phí phát sinh"
              visible={incurredModal.visible}
              onCancel={closeIncurredModal}
              onOk={() => {
                let amount = parseInt(incurredTotal.replaceAll(",", ""));
                formIncurred
                  .getFieldValue("incurreds")
                  ?.forEach((incurred: any) => {
                    if (incurred.verified && incurred.type === "PLAN")
                      amount += parseInt(incurred.amount);
                  });
                setIncurredTotal(new Intl.NumberFormat().format(amount));
                setIncurredModal({
                  ...incurredModal,
                  visible: false,
                  data: formIncurred.getFieldValue("incurreds"),
                });
              }}
            >
              <Table
                bordered={false}
                columns={incurredColumns}
                dataSource={incurredModal.data}
                pagination={false}
              />
              <br />
              <Form {...layout} form={formIncurred}>
                <Form.List name="incurreds">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <Row
                          style={{
                            borderBottom: "1px solid #eee",
                            marginBottom: 20,
                            padding: 10,
                          }}
                          gutter={8}
                        >
                          <Col span={8}>
                            <Form.Item
                              label="Tên"
                              name={[field.name, "title"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Tên phát sinh là bắt buộc",
                                },
                              ]}
                            >
                              <Input placeholder="Tên phát sinh" />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              label="Chi phí"
                              name={[field.name, "amount"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Chi phí là bắt buộc",
                                },
                              ]}
                            >
                              <Input placeholder="Số tiền" />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              label="Loại"
                              name={[field.name, "type"]}
                              initialValue="PLAN"
                            >
                              <Select disabled>
                                <Option value="PLAN">Dự kiến</Option>
                                <Option value="ACTUAL">Thực tế</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              label="Trạng thái"
                              name={[field.name, "verified"]}
                              initialValue="TRUE"
                            >
                              <Select>
                                <Option value="TRUE">Duyệt</Option>
                                <Option value="FALSE">Không duyệt</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={16}>
                            <Form.Item
                              label="Mô tả"
                              name={[field.name, "description"]}
                            >
                              <Input.TextArea rows={1} placeholder="Mô tả" />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item>
                              <Button
                                danger
                                type="dashed"
                                onClick={() => remove(field.name)}
                                block
                                icon={<MinusOutlined />}
                              ></Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        ></Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form>
            </Modal>
          </Form>
        </Spin>
      </Row>
    </div>
  );
}

export default UpdateOrder;
