import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Upload,
  Divider,
  Select,
  Typography,
  Radio,
  Spin,
  Space,
  InputNumber,
} from "antd";
import { UploadOutlined, LeftOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import API from "../../../shared/api/api";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { dateFormatYMD } from "../../../shared/dataFormat";
import {
  rule_require,
  rule_email,
  rule_phoneNumber,
  rule_number,
} from "../../../shared/validateInput";
import {
  beforeUpload,
  onPreview,
  propsImage,
} from "../../../shared/imageHandle";
import UserInfoForm from "../../../shared/Components/UserInfoForm/UserInfoForm";
import AddressForm from "../../../shared/Components/AddressForm.tsx/AddressForm";
import { LICENSE_TYPE_OPTIONS, SEX_OPTION } from "../../../shared/define";
import UserUpdateInfomation from "./../../../Components/UserUpdateInfomation";
import MomentDatePicker from "../../../Components/MomentDatePicker";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Option } = Select;
const { Title, Text } = Typography;

function UpdateDriver() {
  const [form] = Form.useForm();
  const [owner, setOwner] = useState(null);
  const [vendorList, setVendorList] = useState([]);
  const [spinning, setSpinning] = useState(true);
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [idFront, setIdFront] = useState<any>([]);
  const [idBack, setIdBack] = useState<any>([]);
  const [licenseFront, setLicenseFront] = useState<any>([]);
  const [licenseBack, setLicenseBack] = useState<any>([]);
  const [fileAvatarList, setFileAvatarList] = useState<any>([]);
  const [driverType, setDriverType] = useState("DRIVER");

  const history = useHistory();
  let { id }: any = useParams();

  useEffect(() => {
    getListVendor();
    loadData();
  }, []);

  const getListVendor = () => {
    API.get("admin/truck-vendor/all")
      .then((res) => {
        const { success, data } = res.data;
        if (success) {
          const listData = data;
          listData?.forEach((item: { key: any; _id: any }) => {
            item.key = item._id;
          });
          setVendorList(listData.sort((a: any, b: any) => a.shortName.localeCompare(b.shortName)));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onUpdating = async () => {
    setLoadingBtn(true);
    const customData = {
      ...form.getFieldsValue(),
      avatar: fileAvatarList[0]?.response?.data.url,
      type: driverType,
      IDCard: {
        ...form.getFieldsValue().IDCard,
        front: idFront[0]?.response?.data.url,
        back: idBack[0]?.response?.data.url,
      },
      license: {
        ...form.getFieldsValue().license,
        front: licenseFront[0]?.response?.data.url,
        back: licenseBack[0]?.response?.data.url,
        ...(form.getFieldsValue().license?.date._d && {
          date: dateFormatYMD(form.getFieldsValue().license?.date._d),
        }),
      },
      dateOfBirth: dateFormatYMD(form.getFieldsValue().dateOfBirth?._d),
    };
    const response = await API.put(`admin/driver/${id}`, customData).then(
      (res) => res.data
    );
    if (response.success) history.push("/resource/driver");
  };
  const loadData = () => {
    setSpinning(true);
    API.get(`admin/driver/${id}`)
      .then((res) => {
        const { success } = res.data;
        if (success) {
          setOwner(res.data.data.owner);
          let listData = res.data.data;
          setFileAvatarList([
            {
              name: listData?.avatar,
              url: listData?.avatar,
              thumbUrl: listData?.avatar,
              response: { data: { url: listData?.avatar } },
            },
          ]);
          setIdFront([
            {
              name: listData?.IDCard?.front,
              url: listData?.IDCard?.front,
              thumbUrl: listData?.IDCard?.front,
              response: { data: { url: listData?.IDCard?.front } },
            },
          ]);
          setIdBack([
            {
              name: listData?.IDCard?.back,
              url: listData?.IDCard?.back,
              thumbUrl: listData?.IDCard?.back,
              response: { data: { url: listData?.IDCard?.back } },
            },
          ]);
          setLicenseFront([
            {
              name: listData?.license?.front,
              url: listData?.license?.front,
              thumbUrl: listData?.license?.front,
              response: { data: { url: listData?.license?.front } },
            },
          ]);
          setLicenseBack([
            {
              name: listData?.license?.back,
              url: listData?.license?.back,
              thumbUrl: listData?.license?.back,
              response: { data: { url: listData?.license?.back } },
            },
          ]);
          listData = {
            ...listData,
            license: {
              ...listData?.license,
              date: moment(listData?.license?.date),
            },
            dateOfBirth: moment(listData?.dateOfBirth),
          };
          console.log(listData);
          setDriverType(listData?.type);
          form.setFieldsValue(listData);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSpinning(false);
      });
  };
  return (
    <Row style={{ width: "100%" }}>
      <Spin spinning={spinning}>
        <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
          <span
            onClick={() => {
              history.goBack();
            }}
          >
            <LeftOutlined />
            Quay lại
          </span>
        </Title>
        <Form
          form={form}
          {...layout}
          style={{ width: "100%" }}
          onFinish={onUpdating}
          onFinishFailed={() => {
            setLoadingBtn(false);
          }}
        >
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Ảnh tài xế"
                name="avatar"
                extra={
                  <Space direction="vertical">
                    <Text>Ảnh không quá 1mb</Text>
                    <Text>Size 200x200px</Text>
                  </Space>
                }
              >
                <ImgCrop rotate>
                  <Upload
                    listType="picture-card"
                    {...propsImage}
                    fileList={fileAvatarList}
                    onChange={({
                      fileList: newFileList,
                    }: {
                      fileList: any;
                    }) => {
                      setFileAvatarList(newFileList);
                    }}
                    onPreview={onPreview}
                    beforeUpload={beforeUpload}
                  >
                    {fileAvatarList.length < 1 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </Form.Item>
            </Col>
            <Col xs={24} sm={16} md={16} lg={16} xl={16}>
              <Row gutter={32}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Tên" name="name" rules={[rule_require]}>
                    <Input placeholder="Nhập tên tài xế" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Tên nhà xe"
                    name={["truckVendor", "_id"]}
                    rules={[rule_require]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Chọn doanh nghiệp"
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {vendorList.map((vendor: any) => (
                        <Option key={vendor._id} value={vendor._id}>
                          {vendor.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Form.Item
                    label="Giới tính"
                    name="sex"
                    rules={[rule_require]}
                  >
                    <Radio.Group>
                      {SEX_OPTION.map((item: any) => (
                        <Radio value={item.value}>{item.label}</Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Form.Item
                    label="Ngày sinh"
                    name="dateOfBirth"
                    rules={[rule_require]}
                  >
                    <MomentDatePicker format="DD/MM/YYYY" />
                  </Form.Item>
                </Col>
                {driverType == "DRIVER" && (
                  <Col xs={24} sm={12}>
                    <Form.Item label="Mã tài xế" name="code">
                      <Input placeholder="Nhập mã tài xế" />
                    </Form.Item>
                  </Col>
                )}
                {driverType == "DELIVERY" && (
                  <Col xs={24} sm={12}>
                    <Form.Item label="Mã giao nhận" name="code">
                      <Input placeholder="Nhập mã người giao nhận" />
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Lương cứng"
                    name="salary"
                    rules={[rule_number]}
                  >
                    <InputNumber
                      formatter={(value) =>
                        ` ${value
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Divider />
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Col xs={24}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      label="CMND/CCCD Người đại diện"
                      name={["IDCard", "number"]}
                      rules={[rule_require, rule_number]}
                      labelCol={{ span: 24 }}
                    >
                      <Input placeholder="Nhập số CMND/CCCD" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      name={["IDCard", "front"]}
                      labelCol={{ span: 24 }}
                      label="Mặt trước"
                    >
                      <ImgCrop aspect={3 / 2} rotate>
                        <Upload
                          listType="picture-card"
                          {...propsImage}
                          fileList={idFront}
                          onChange={({
                            fileList: newFileList,
                          }: {
                            fileList: any;
                          }) => {
                            setIdFront(newFileList);
                          }}
                          onPreview={onPreview}
                          beforeUpload={beforeUpload}
                        >
                          {idFront.length < 1 && "+ Upload"}
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      name={["IDCard", "back"]}
                      labelCol={{ span: 24 }}
                      label="Mặt sau"
                    >
                      <ImgCrop aspect={3 / 2} rotate>
                        <Upload
                          listType="picture-card"
                          {...propsImage}
                          fileList={idBack}
                          onChange={({
                            fileList: newFileList,
                          }: {
                            fileList: any;
                          }) => {
                            setIdBack(newFileList);
                          }}
                          onPreview={onPreview}
                          beforeUpload={beforeUpload}
                        >
                          {idBack.length < 1 && "+ Upload"}
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Col xs={24}>
                <Row>
                  {driverType == "DRIVER" && (
                    <>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                          label="Số bằng lái"
                          name={["license", "number"]}
                          rules={[rule_require, rule_number]}
                          labelCol={{ span: 24 }}
                        >
                          <Input placeholder="Nhập số bằng lái" />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          name={["license", "front"]}
                          // rules={[rule_require]}
                          labelCol={{ span: 24 }}
                          label="Mặt trước"
                        >
                          <ImgCrop aspect={3 / 2} rotate>
                            <Upload
                              listType="picture-card"
                              {...propsImage}
                              fileList={licenseFront}
                              onChange={({
                                fileList: newFileList,
                              }: {
                                fileList: any;
                              }) => {
                                setLicenseFront(newFileList);
                              }}
                              onPreview={onPreview}
                              beforeUpload={beforeUpload}
                            >
                              {licenseFront.length < 1 && "+ Upload"}
                            </Upload>
                          </ImgCrop>
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          name={["license", "back"]}
                          // rules={[rule_require]}
                          label="Mặt sau"
                          labelCol={{ span: 24 }}
                        >
                          <ImgCrop aspect={3 / 2} rotate>
                            <Upload
                              listType="picture-card"
                              {...propsImage}
                              fileList={licenseBack}
                              onChange={({
                                fileList: newFileList,
                              }: {
                                fileList: any;
                              }) => {
                                setLicenseBack(newFileList);
                              }}
                              onPreview={onPreview}
                              beforeUpload={beforeUpload}
                            >
                              {licenseBack.length < 1 && "+ Upload"}
                            </Upload>
                          </ImgCrop>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Row>
                {driverType == "DRIVER" && (
                  <>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label="Loại bằng"
                        name={["license", "type"]}
                        rules={[rule_require]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Search to Select"
                        >
                          {LICENSE_TYPE_OPTIONS.map((item: any, index: any) => (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label="Thời hạn bằng"
                        name={["license", "date"]}
                        rules={[rule_require]}
                      >
                        <MomentDatePicker
                          format={"DD/MM/YYYY"}
                          onChange={(date, dateString) => {
                            console.log(dateString);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
            <Divider />
            <AddressForm />
            <Divider />
          </Row>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
            <Button
              loading={isLoadingBtn}
              htmlType="submit"
              style={{
                width: "100%",
                backgroundColor: "#03A9F4",
                color: "white",
              }}
            >
              Cập nhật
            </Button>
          </Col>
        </Form>
        {owner && <UserUpdateInfomation owner={owner} hideEmail />}
      </Spin>
    </Row>
  );
}
export default UpdateDriver;
