import {Select} from "antd";

const {Option} = Select;

export const CombinationList: React.FC<{
  value?: any[];
  disabled?: boolean;
  showCustomer: boolean;
  onChange?: (value: any) => void;
  listOptions: any[];
  style?: any
}> = ({showCustomer = true, disabled = false, value, onChange, listOptions, style}) => {
  return (
    <Select
      disabled={disabled}
      // value={value}
      size="middle"
      placeholder="Chọn tuyến"
      style={{width: 300, ...style}}
      onChange={(value, option) => {
        onChange?.(listOptions.find(item => item._id === value));
      }}
      popupMatchSelectWidth={false}
    >
      {listOptions?.map((item) => {
        return (
          <Option
            value={item._id}
          >{showCustomer && `[${item?.customer?.name}]`}{`[${item?.code}] ${item?.description}`}</Option>
        );
      })}
    </Select>
  );
};
